import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ReactComponent as FRDIcon } from 'assets/imgs/marketCap/FRD.svg'
import { BaseButton, Pending } from 'components/Button'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useUserStakedListQuery } from 'graphql/thegraph/LaunchpadQuery'
import { useMemeStakingContract } from 'hooks/useContract'
import { useApr, useRewardsMeme } from 'pages/MarketCap/InfoDetail/hooks'
import { useCallback, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN } from 'utils/bn'
import { formatAmount } from 'utils/formatAmout'
import { handlerError } from 'utils/formatError'

import { StyledRewardsList, StyledToIcon, StyledTrRow } from './RewardsList'
import StakeDialog from './StakeDialog'
import UnstakeDialog from './UnStakeDialog'
import { StyledClaimBtn } from './StakedList'

export const StyledItem = styled(Box)`
  background-color: ${({ theme }) => theme.themeBg};
  border-radius: 16px;
  .token {
    padding: 16px 8px 20px;
    border-bottom: 1px solid ${({ theme }) => theme.secondaryBorder};
  }
  .info {
    padding: 16px 8px;
  }
`

export const StyledStakeBtn = styled(BaseButton)`
  width: fit-content;
  height: 36px;
  min-height: 36px;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.stakeBtnBg};
  border-radius: 8px;
`

function EarnReward({ memeStaking }: any) {
  const reward = useRewardsMeme(memeStaking)
  return (
    <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
      {formatAmount(reward, 2, true)}
    </ThemedText.TextPrimary>
  )
}

export default function StakedListM() {
  const { chainId, account, provider } = useActiveChainId()

  const [attemptingTxn_claim, setAttemptingTxn_claim] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')

  const { data } = useUserStakedListQuery()
  // console.log(data)

  const [stakedAmount, setStakedAmount] = useState(0)
  const [memeStakingAddress, setMemeStakingAddress] = useState('')
  const [tokenImage_url, setTokenImage_url] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [tokenAddress, setTokenAddress] = useState('')

  const [openStake, setOpenStake] = useState(false)
  const handleOpenStakeDialog = () => {
    setOpenStake(true)
  }
  const handleCloseStake = () => {
    setOpenStake(false)
  }

  const [openUnstake, setOpenUnstake] = useState(false)
  const handleOpenUnstakeDialog = () => {
    setOpenUnstake(true)
  }
  const handleCloseUnstake = () => {
    setOpenUnstake(false)
  }

  const addTransaction = useTransactionAdder()

  const StakingContract = useMemeStakingContract(memeStakingAddress)

  const apr = useApr(memeStakingAddress)

  const handlerClaim = useCallback(async () => {
    if (!memeStakingAddress || !StakingContract || !account) return
    setAttemptingTxn_claim(true)
    try {
      const response = await StakingContract.getReward(account)
      setAttemptingTxn_claim(false)
      addTransaction(response, {
        type: TransactionType.CLAIM_REWARDS,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn_claim(false)
      setTxError(handlerError(error))
      console.log('claim error', error)
    }
  }, [StakingContract, account, addTransaction, memeStakingAddress])

  return (
    <StyledRewardsList>
      {data &&
        data.map((item: any, index: any) => (
          <StyledItem key={index}>
            <div className="token">
              <NavLink to={`/infoDetail?token=${item.tokenInfo.id}`} style={{ textDecoration: 'none' }}>
                <Box display="flex" alignItems="center" gap="8px">
                  <img src={item.tokenInfo.metadata.image_url} width="30px" height="30px" />
                  <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
                    <Trans>{item.tokenInfo.symbol}</Trans>
                  </ThemedText.TextPrimary>
                  <StyledToIcon />
                </Box>
              </NavLink>
            </div>
            <div className="info">
              <Box display="flex" alignItems="center" justifyContent="space-between" gap="8px">
                <Box display="flex" flexDirection="column" gap="4px" alignItems="flex-start">
                  <ThemedText.TextSecondary fontSize={14} fontWeight={400}>
                    <Trans>Staked Amount</Trans>
                  </ThemedText.TextSecondary>
                  <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
                    {formatAmount(item.amount_formatted, 2, true)}
                  </ThemedText.TextPrimary>
                </Box>
                <Box display="flex" flexDirection="column" gap="4px" alignItems="flex-end">
                  <ThemedText.TextSecondary fontSize={14} fontWeight={400}>
                    <Trans>Your Earned</Trans>
                  </ThemedText.TextSecondary>
                  <Box display="flex" alignItems="center" gap="8px">
                    <FRDIcon width="16px" height="16px" />
                    <EarnReward memeStaking={item.memeStakingAddress} />
                  </Box>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" gap="16px" mt="16px" justifyContent="space-between">
                <StyledStakeBtn
                  className="flex-1"
                  onClick={() => {
                    setStakedAmount(item.amount_formatted)
                    setMemeStakingAddress(item.memeStakingAddress)
                    setTokenImage_url(item.tokenInfo.metadata.image_url)
                    setTokenSymbol(item.tokenInfo.symbol)
                    setTokenAddress(item.tokenInfo.id)
                    handleOpenStakeDialog()
                  }}
                >
                  <ThemedText.TextPrimary fontSize={14} fontWeight={700} className="active-gradient-text">
                    <Trans>STAKE</Trans>
                  </ThemedText.TextPrimary>
                </StyledStakeBtn>
                <StyledStakeBtn
                  className="flex-1"
                  onClick={() => {
                    setStakedAmount(item.amount_formatted)
                    setMemeStakingAddress(item.memeStakingAddress)
                    setTokenImage_url(item.tokenInfo.metadata.image_url)
                    setTokenSymbol(item.tokenInfo.symbol)
                    handleOpenUnstakeDialog()
                  }}
                >
                  <ThemedText.TextPrimary fontSize={14} fontWeight={700} className="active-gradient-text">
                    <Trans>UNSTAKE</Trans>
                  </ThemedText.TextPrimary>
                </StyledStakeBtn>
                <StyledClaimBtn
                  className="flex-1"
                  onClick={() => {
                    setMemeStakingAddress(item.memeStakingAddress)
                    handlerClaim()
                  }}
                  disabled={attemptingTxn_claim}
                >
                  {attemptingTxn_claim ? <Pending /> : 'CLAIM'}
                </StyledClaimBtn>
              </Box>
            </div>
          </StyledItem>
        ))}
      <StakeDialog
        open={openStake}
        handleClose={handleCloseStake}
        stakedAmount={BN(stakedAmount)}
        apr={apr}
        tokenName={tokenSymbol}
        tokenIcon={tokenImage_url}
        tokenAddress={tokenAddress}
        StakingContract={StakingContract}
      />
      <UnstakeDialog
        open={openUnstake}
        handleClose={handleCloseUnstake}
        stakedAmount={BN(stakedAmount)}
        StakingContract={StakingContract}
        tokenName={tokenSymbol}
        tokenIcon={tokenImage_url}
      />
    </StyledRewardsList>
  )
}
