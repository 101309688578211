import { Pagination } from '@mui/material'
import styled from 'styled-components/macro'

export const StyledPagination = styled(Pagination)`
  .MuiPaginationItem-root {
    font-size: 14px;
    font-family: 'TTHoves';
    color: ${({ theme }) => theme.textSecondary};
  }
  .MuiPaginationItem-root.Mui-selected {
    background-color: ${({ theme }) => theme.paginationActiveBg};
    color: ${({ theme }) => theme.textPrimary};
  }
  .MuiPaginationItem-root.Mui-selected:hover {
    background-color: ${({ theme }) => theme.paginationActiveBg};
  }
`
