import { atom } from 'jotai'
import { useUpdateAtom } from 'jotai/utils'
import { UniswapXOrderStatus } from 'lib/hooks/orders/types'
import { useCallback } from 'react'
import { UniswapXOrderDetails } from 'state/signatures/types'

type SelectedOrderInfo = {
  modalOpen?: boolean
  orderHash: string
  status: UniswapXOrderStatus
  details?: UniswapXOrderDetails
}

const selectedOrderAtom = atom<SelectedOrderInfo | undefined>(undefined)

export function useOpenOffchainActivityModal() {
  const setSelectedOrder = useUpdateAtom(selectedOrderAtom)

  return useCallback(
    (order: { orderHash: string; status: UniswapXOrderStatus }) => setSelectedOrder({ ...order, modalOpen: true }),
    [setSelectedOrder]
  )
}
