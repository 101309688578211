import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ReactComponent as FRDIcon } from 'assets/imgs/marketCap/FRD.svg'
import { ReactComponent as ETHIcon } from 'assets/imgs/stake/eth.svg'
import { ReactComponent as ToIcon } from 'assets/imgs/stake/to2.svg'
import { BaseButton } from 'components/Button'
import { useLaunchpadContract, useMemeStakingContract } from 'hooks/useContract'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useApr } from 'pages/MarketCap/InfoDetail/hooks'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN, fromWei } from 'utils/bn'
import { countZeros } from 'utils/countZeros'
import { formatAmount2 } from 'utils/formatAmout'

import StakeDialog from './StakeDialog'

export const StyledToIcon = styled(ToIcon)`
  path {
    stroke: ${({ theme }) => theme.textSecondary};
  }
`

export const StyledRewardsList = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const StyledRewardsRow = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 16px 8px;
  background-color: ${({ theme }) => theme.themeBg};
  border-radius: 16px;
`
export const StyledTrRow = styled(StyledRewardsRow)``
export const StyledThRow = styled(StyledRewardsRow)``

export const StyledStakeBtn = styled(BaseButton)`
  width: fit-content;
  height: 36px;
  min-height: 36px;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 8px;
`

function EpochTokenFee({ memeAddress, roundId }: any) {
  const LaunchpadContract = useLaunchpadContract()

  const { result: fee } = useSingleCallResult(LaunchpadContract, 'tokenFee', [roundId * 7200, memeAddress])

  return (
    <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
      {countZeros(fromWei(fee?.toString() || 0).toFixed())}
    </ThemedText.TextPrimary>
  )
}

export default function RewardsList({ epochList }: any) {
  const [stakedAmount, setStakedAmount] = useState(0)
  const [memeStakingAddress, setMemeStakingAddress] = useState('')
  const [tokenImage_url, setTokenImage_url] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [tokenAddress, setTokenAddress] = useState('')

  const StakingContract = useMemeStakingContract(memeStakingAddress)

  const apr = useApr(memeStakingAddress)

  const [openStake, setOpenStake] = useState(false)
  const handleOpenStakeDialog = () => {
    setOpenStake(true)
  }
  const handleCloseStake = () => {
    setOpenStake(false)
  }

  return (
    <StyledRewardsList>
      <StyledTrRow>
        <Box p="0 8px">
          <ThemedText.TextSecondary fontSize={14} fontWeight={400}>
            <Trans>Token</Trans>
          </ThemedText.TextSecondary>
        </Box>
        <Box display="flex" justifyContent="flex-end" p="0 8px">
          <ThemedText.TextSecondary fontSize={14} fontWeight={400}>
            <Trans>Fees</Trans>
          </ThemedText.TextSecondary>
        </Box>
        <Box display="flex" justifyContent="flex-end" p="0 8px">
          <ThemedText.TextSecondary fontSize={14} fontWeight={400}>
            <Trans>Pool's Rewards</Trans>
          </ThemedText.TextSecondary>
        </Box>
        <Box display="flex" justifyContent="flex-end" p="0 8px"></Box>
      </StyledTrRow>
      {epochList &&
        epochList.map((item: any, i: any) => (
          <StyledThRow key={i}>
            <Box display="flex" alignItems="center" gap="8px" p="0 8px">
              <img src={item.memeToken.metadata.image_url} width="30px" height="30px" />
              <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
                <Trans>{item.memeToken.symbol}</Trans>
              </ThemedText.TextPrimary>
            </Box>
            <Box display="flex" alignItems="center" gap="8px" justifyContent="flex-end" p="0 8px">
              <ETHIcon width="30px" height="30px" />
              <EpochTokenFee memeAddress={item.memeToken.id} roundId={item.roundId} />
              {/* <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
              666
            </ThemedText.TextPrimary> */}
            </Box>
            <Box display="flex" alignItems="center" gap="8px" justifyContent="flex-end" p="0 8px">
              <FRDIcon width="30px" height="30px" />
              <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
                {formatAmount2(item.amount, 18, 2, true)}
              </ThemedText.TextPrimary>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              gap="4px"
              justifyContent="flex-end"
              p="0 8px"
              onClick={() => {
                setStakedAmount(item.amount_formatted)
                setMemeStakingAddress(item.memeStakingAddress)
                setTokenImage_url(item.memeToken.metadata.image_url)
                setTokenSymbol(item.memeToken.symbol)
                setTokenAddress(item.memeToken.id)
                handleOpenStakeDialog()
              }}
            >
              <StyledStakeBtn>
                <ThemedText.TextPrimary fontSize={14} fontWeight={700} className="active-gradient-text">
                  <Trans>STAKE</Trans>
                </ThemedText.TextPrimary>
              </StyledStakeBtn>
              <StyledToIcon />
            </Box>
          </StyledThRow>
        ))}
      <StakeDialog
        open={openStake}
        handleClose={handleCloseStake}
        stakedAmount={BN(stakedAmount)}
        apr={apr}
        tokenName={tokenSymbol}
        tokenIcon={tokenImage_url}
        tokenAddress={tokenAddress}
        StakingContract={StakingContract}
      />
    </StyledRewardsList>
  )
}
