import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ReactComponent as CloseIcon } from 'assets/imgs/stake/close.svg'
import { ReactComponent as ToIcon } from 'assets/imgs/stake/to.svg'
import { Pending } from 'components/Button'
import MaxTab from 'components/MaxTab'
import NumericalInput from 'components/NumericalInput'
import { numberToHex, StyledInputBox } from 'pages/MarketCap/InfoDetail/Swap'
import { useCallback, useMemo, useState } from 'react'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import { ThemedText } from 'theme'
import { BN } from 'utils/bn'
import { formatAmount } from 'utils/formatAmout'
import { handlerError } from 'utils/formatError'

import { StyledUnstakeBtn } from '.'
import { StyledDialog, StyledInfo } from './StakeDialog'

export default function UnstakeDialog({ open, handleClose, stakedAmount, StakingContract, tokenName, tokenIcon }: any) {
  const [attemptingTxn, setAttemptingTxn] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')
  const pendingText = 'pending ...'

  const [unStakeAmount, setUnstakeAmount] = useState('')
  const onUserInput = (amount: any) => {
    setUnstakeAmount(amount)
  }

  const isInsufficientBalance = useMemo(() => {
    if (!unStakeAmount || unStakeAmount == '0' || unStakeAmount == '') return false
    if (!stakedAmount || Number(stakedAmount) == 0) return false
    if (BN(unStakeAmount).gt(stakedAmount)) {
      return true
    }
    return false
  }, [stakedAmount, unStakeAmount])

  const addTransaction = useTransactionAdder()
  const handleUnstake = useCallback(async () => {
    if (!unStakeAmount || !StakingContract) return

    setAttemptingTxn(true)
    try {
      const response = await StakingContract.withdraw(numberToHex(unStakeAmount))
      setAttemptingTxn(false)
      addTransaction(response, {
        type: TransactionType.UNSTAKE,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn(false)
      setTxError(handlerError(error))
      console.log('unstake error', error)
    }
  }, [StakingContract, addTransaction, unStakeAmount])

  const onUserMaxTab = useCallback(
    (value: number | string) => {
      if (!stakedAmount || Number(stakedAmount) == 0) return
      const max = stakedAmount.multipliedBy(value).toFixed(18)
      onUserInput(max)
    },
    [stakedAmount]
  )

  return (
    <StyledDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <ThemedText.TextSecondary fontWeight={700} fontSize={12}>
          <Trans>Unstake {tokenName}</Trans>
        </ThemedText.TextSecondary>
        <CloseIcon onClick={handleClose} />
      </Box>
      <Box display="flex" flexDirection="column" mt="8px" gap="16px">
        <StyledInputBox>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px">
            <NumericalInput value={unStakeAmount} onUserInput={onUserInput} align="left" />
            <Box display="flex" flexDirection="column" alignItems="flex-end" gap="8px">
              <Box display="flex" alignItems="center" gap="8px">
                <img src={tokenIcon} width="16px" height="16px" />
                <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
                  {tokenName}
                </ThemedText.TextPrimary>
              </Box>
              <ThemedText.TextSecondary fontWeight={700} fontSize={12}>
                Balance:{formatAmount(stakedAmount, 2, true)}
              </ThemedText.TextSecondary>
            </Box>
          </Box>
          <Box mt="10px">
            <MaxTab onChange={onUserMaxTab} />
          </Box>
        </StyledInputBox>
        <StyledInfo>
          <Box display="flex" justifyContent="space-between" alignItems="center" gap="8px" className="info-item">
            <ThemedText.TextSecondary fontWeight={400} fontSize={14}>
              <Trans>Staked</Trans>
            </ThemedText.TextSecondary>
            {stakedAmount && unStakeAmount ? (
              <Box display="flex" alignItems="center" gap="4px">
                <ThemedText.TextSecondary fontWeight={400} fontSize={14}>
                  {formatAmount(stakedAmount, 2, true)}
                </ThemedText.TextSecondary>
                <ToIcon />
                <ThemedText.TextPrimary fontWeight={400} fontSize={14}>
                  {formatAmount(stakedAmount.minus(BN(unStakeAmount)), 2, true)}
                </ThemedText.TextPrimary>
              </Box>
            ) : (
              <Box display="flex" alignItems="center" gap="4px">
                <ThemedText.TextSecondary fontWeight={400} fontSize={14}>
                  {formatAmount(stakedAmount, 2, true)}
                </ThemedText.TextSecondary>
              </Box>
            )}
          </Box>
        </StyledInfo>
        <StyledUnstakeBtn onClick={handleUnstake} disabled={isInsufficientBalance || attemptingTxn}>
          {isInsufficientBalance ? 'Insufficient balance ' : attemptingTxn ? <Pending /> : 'UNSTAKE'}
        </StyledUnstakeBtn>
      </Box>
    </StyledDialog>
  )
}
