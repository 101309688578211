import { useLaunchpadQuery } from 'graphql/thegraph/LaunchpadQuery'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setEthPrice, setLaunchpadQueryList, setRoxPrice } from './actions'

export default function Updater() {
  const dispatch = useDispatch()

  const launchpadData = useLaunchpadQuery()
  // console.log('launchpadData', launchpadData)

  // const ethPrice = useEthPriceUSD()
  // const roxPrice = useRoxPriceETH()

  useEffect(() => {
    dispatch(setLaunchpadQueryList({ list: launchpadData }))
  }, [launchpadData, dispatch])

  useEffect(() => {
    dispatch(setEthPrice({ price: launchpadData?.ethPriceUSD }))
  }, [dispatch, launchpadData?.ethPriceUSD])

  useEffect(() => {
    dispatch(setRoxPrice({ price: launchpadData?.baseTokenPriceUSD }))
  }, [dispatch, launchpadData?.baseTokenPriceUSD])
  return null
}
