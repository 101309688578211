import { Currency, CurrencyAmount, TradeType } from '@uniswap/sdk-core'
import { Trade } from '@uniswap/v3-sdk'
import { DebounceSwapQuoteVariant, useDebounceSwapQuoteFlag } from 'featureFlags/flags/debounceSwapQuote'
import { useMemo } from 'react'
import { RouterPreference } from 'state/routing/slice'
import { ClassicTrade, InterfaceTrade, QuoteMethod, TradeState } from 'state/routing/types'

import { useClientSideV3Trade } from './useClientSideV3Trade'
import useDebounce from './useDebounce'

// Prevents excessive quote requests between keystrokes.
const DEBOUNCE_TIME = 350

// Temporary until we remove the feature flag.
const DEBOUNCE_TIME_INCREASED = 650

export function useBestTrade(
  tradeType: TradeType,
  amountSpecified?: CurrencyAmount<Currency>,
  otherCurrency?: Currency,
  routerPreferenceOverride?: RouterPreference.X,
  account?: string
): {
  state: TradeState
  trade?: InterfaceTrade
  v3trade?: Trade<Currency, Currency, TradeType>
}

export function useBestTrade(
  tradeType: TradeType,
  amountSpecified?: CurrencyAmount<Currency>,
  otherCurrency?: Currency,
  routerPreferenceOverride?: RouterPreference.API | RouterPreference.CLIENT,
  account?: string
): {
  state: TradeState
  trade?: ClassicTrade
}
/**
 * Returns the best v2+v3 trade for a desired swap.
 * @param tradeType whether the swap is an exact in/out
 * @param amountSpecified the exact amount to swap in/out
 * @param otherCurrency the desired output/payment currency
 */
export function useBestTrade(
  tradeType: TradeType,
  amountSpecified?: CurrencyAmount<Currency>,
  otherCurrency?: Currency
): {
  state: TradeState
  trade?: InterfaceTrade
  method?: QuoteMethod
  v3trade?: Trade<Currency, Currency, TradeType>
} {
  const debouncedSwapQuoteFlagEnabled = useDebounceSwapQuoteFlag() === DebounceSwapQuoteVariant.Enabled
  const [debouncedAmount, debouncedOtherCurrency] = useDebounce(
    useMemo(() => [amountSpecified, otherCurrency], [amountSpecified, otherCurrency]),
    debouncedSwapQuoteFlagEnabled ? DEBOUNCE_TIME_INCREASED : DEBOUNCE_TIME
  )

  // only use client side router if routing api trade failed or is not supported
  const bestV3Trade = useClientSideV3Trade(tradeType, debouncedAmount, debouncedOtherCurrency)

  const isLoading = bestV3Trade.state === TradeState.LOADING

  // only return gas estimate from api if routing api trade is used
  return useMemo(
    () => ({
      ...bestV3Trade,
      ...(isLoading ? { state: TradeState.LOADING } : {}),
    }),
    [bestV3Trade, isLoading]
  )
}
