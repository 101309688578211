import { createReducer } from '@reduxjs/toolkit'
import { parsedQueryString } from 'hooks/useParsedQueryString'
import { AllPoolsProps } from 'hooks/useQueryAllPools'

import {
  Field,
  positionSelectCurrency,
  positionSwitchCurrencies,
  positionTypeInput,
  setLimit,
  setLong,
  setPoolInfo,
  setSliederLever,
  setToken0,
  TRADESATE,
} from './actions'
import { queryParametersToSwapState } from './hooks'

export interface SwapState {
  readonly independentField: Field
  readonly typedValue: string
  readonly [Field.INPUT]: {
    readonly currencyId?: string | null
  }
  readonly [Field.OUTPUT]: {
    readonly currencyId?: string | null
  }
  readonly isLong: TRADESATE
  readonly sliederLever: number
  readonly poolInfo?: AllPoolsProps
  readonly isToken0: boolean
  readonly isLimit: boolean
}

const initialState: SwapState = queryParametersToSwapState(parsedQueryString())

export default createReducer<SwapState>(initialState, (builder) =>
  builder
    .addCase(positionSelectCurrency, (state, { payload: { currencyId, field } }) => {
      const otherField = field === Field.INPUT ? Field.OUTPUT : Field.INPUT
      if (currencyId === state[otherField].currencyId) {
        // the case where we have to swap the order
        return {
          ...state,
          independentField: state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
          [field]: { currencyId },
          [otherField]: { currencyId: state[field].currencyId },
        }
      } else {
        // the normal case
        return {
          ...state,
          [field]: { currencyId },
        }
      }
    })
    .addCase(positionSwitchCurrencies, (state) => {
      return {
        ...state,
        independentField: state.independentField === Field.INPUT ? Field.OUTPUT : Field.INPUT,
        [Field.INPUT]: { currencyId: state[Field.OUTPUT].currencyId },
        [Field.OUTPUT]: { currencyId: state[Field.INPUT].currencyId },
      }
    })
    .addCase(positionTypeInput, (state, { payload: { field, typedValue } }) => {
      return {
        ...state,
        independentField: field,
        typedValue,
      }
    })
    .addCase(setLong, (state, { payload: { isLong } }) => {
      return {
        ...state,
        isLong,
      }
    })
    .addCase(setSliederLever, (state, { payload: { sliederLever } }) => {
      state.sliederLever = sliederLever
    })
    .addCase(setPoolInfo, (state, { payload: { poolInfo } }) => {
      return {
        ...state,
        poolInfo,
      }
    })
    .addCase(setToken0, (state, { payload: { isToken0 } }) => {
      return {
        ...state,
        isToken0,
      }
    })
    .addCase(setLimit, (state, { payload: { limit } }) => {
      return {
        ...state,
        isLimit: limit,
      }
    })
)
