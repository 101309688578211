import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { StyledDiscordIcon, StyledDocIcon, StyledTelegramIcon, StyledTwitterIcon } from 'components/Icons/StyledIcon'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const StyledReleases = styled.div`
  display: flex;
  align-items: center;
  transition: 250ms ease;
  a {
    display: flex;
    align-items: center;
  }
  .line {
    margin: 0 16px;
    width: 1px;
    height: 14px;
    background-color: ${({ theme }) => theme.textTertiary};
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoint.md}px) {
    padding: 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 9;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    /* position: fixed; 
    bottom: 12px;
    z-index: 9;*/
    .line {
      margin: 0 12px;
    }
  }
`

export default function Releases() {
  return (
    <StyledReleases>
      <ThemedText.TextSecondary fontSize={14}>
        <Trans>beta v1.0.0</Trans>
      </ThemedText.TextSecondary>
      <div className="line"></div>
      <Box display="flex" alignItems="center" gap="16px">
        <a href="https://discord.gg/xqPt5gJfxk" target="_blank" rel="noreferrer">
          <StyledDiscordIcon />
        </a>
        <a href="https://twitter.com/Roguex_io" target="_blank" rel="noreferrer">
          <StyledTwitterIcon />
        </a>
        <a href="https://t.me/roguexdotio" target="_blank" rel="noreferrer">
          <StyledTelegramIcon />
        </a>
        <a href="https://docs.roguex.io/" target="_blank" rel="noreferrer">
          <StyledDocIcon />
        </a>
      </Box>
    </StyledReleases>
  )
}
