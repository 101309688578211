import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ButtonGray } from 'components/Button'
import Column from 'components/Column'
import { StyledDropDown2 } from 'components/Icons/StyledIcon'
import { NavDropdown } from 'components/NavBar/NavDropdown2'
import Row from 'components/Row'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import { useRef, useState } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'

const EpochList = styled(Column)`
  min-width: 200px;
  .epochHeader {
    padding: 6px 16px;
    border-bottom: 1px solid ${({ theme }) => theme.swapSectionBorder};
  }
  .epochItem {
    padding: 4px 16px;
    cursor: pointer;
    &:hover {
      background: ${({ theme }) => theme.buttonDisabledBackground};
    }
  }
`

const VeRoxSelect = styled(ButtonGray) <{
  disabled?: boolean
}>`
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.backgroundInteractive};
  cursor: pointer;
  width: fix-content;
  height: 26px;
  min-height: 26px;
  padding: 4px 8px;
  border-radius: 5px;
  outline: none;
  user-select: none;
  font-size: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.textSecondary};
  gap: 8px;
  text-wrap: nowrap;
  &:hover,
  &:active {
    background-color: ${({ theme }) => theme.backgroundInteractive};
  }

  &:before {
    background-size: 100%;
    border-radius: inherit;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
  }

  &:hover:before {
    background-color: ${({ theme }) => theme.stateOverlayHover};
  }

  &:active:before {
    background-color: ${({ theme }) => theme.stateOverlayPressed};
  }
`

export default function EpochDropdown({ epochNum, setEpochNum, epochLength }: any) {
  const [isOpen, setIsOpen] = useState(false)
  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }
  const epochNode = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(epochNode, isOpen ? toggleOpen : undefined)


  return (
    <div style={{ position: 'relative' }} ref={epochNode}>
      <VeRoxSelect
        onClick={() => {
          toggleOpen()
        }}
      >
        <ThemedText.TextSecondary fontSize={14}>
          <Trans>Epoch {Number(epochNum+1)}</Trans>
        </ThemedText.TextSecondary>
        <StyledDropDown2 />
      </VeRoxSelect>
      {isOpen && (
        <NavDropdown right="0">
          <EpochList>
            <Row className="epochHeader">
              <Box width="80px">
                <ThemedText.TextSecondary fontWeight={700} fontSize={12}>
                  Epoch
                </ThemedText.TextSecondary>
              </Box>
            </Row>
            <Column gap="6px" width="100%" style={{ paddingTop: '8px' }}>
              {epochLength > 0 &&
                Array.from(new Array(epochLength).keys()).map((item, index) => {
                  return (
                    <Row
                      className="epochItem"
                      onClick={() => {
                        setEpochNum(item), toggleOpen()
                      }}
                      key={index}
                    >
                      <Box width="80px">
                        <ThemedText.TextPrimary>{Number(item) + 1}</ThemedText.TextPrimary>
                      </Box>
                    </Row>
                  )
                })}
            </Column>
          </EpochList>
        </NavDropdown>
      )}
    </div>
  )
}
