/* eslint-disable no-case-declarations */
import { t, Trans } from '@lingui/macro'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import BigFloatNumber from 'bignumber.js'
import { useActiveChainId } from 'connection/useActiveChainId'
import { ApprovalState, useApproveCallback } from 'hooks/useApproveCallback'
import { useCurrencyBalances } from 'lib/hooks/useCurrencyBalance'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { ButtonConfirmed, ButtonPrimary, Pending } from '../Button'

export function useDynamicApprove(
  currencyAmounts: (CurrencyAmount<Currency> | undefined)[] | undefined,
  spender: string | undefined
) {
  const { account } = useActiveChainId()

  const currencies = useMemo(() => currencyAmounts?.map((x) => x?.currency), [currencyAmounts])

  const balances = useCurrencyBalances(account ?? undefined, currencies)
  const balanceMap = useMemo(() => {
    return balances.reduce<{
      [address: string]: CurrencyAmount<Currency>
    }>((memo, curr) => {
      const address = curr?.currency.wrapped.address
      if (!address) return memo

      memo[address] = curr
      return memo
    }, {})
  }, [balances])

  const [AllPening, setAllPening] = useState(false)

  const [approveState0, approve0] = useApproveCallback(currencyAmounts?.[0], spender)
  const [approveState1, approve1] = useApproveCallback(currencyAmounts?.[1], spender)
  const [approveState2, approve2] = useApproveCallback(currencyAmounts?.[2], spender)
  const [approveState3, approve3] = useApproveCallback(currencyAmounts?.[3], spender)
  const [approveState4, approve4] = useApproveCallback(currencyAmounts?.[4], spender)
  const {
    shouldApproveNum,
    shouldApproveData,
    pendingApproveNum,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    pendingApproveData,
    unknownApproveNum,
    // approveStates,
  } = useMemo(() => {
    if (!currencyAmounts) return {}

    const approveStates = [
      { approve: approve0, state: approveState0, amount: currencyAmounts[0] },
      { approve: approve1, state: approveState1, amount: currencyAmounts[1] },
      { approve: approve2, state: approveState2, amount: currencyAmounts[2] },
      { approve: approve3, state: approveState3, amount: currencyAmounts[3] },
      { approve: approve4, state: approveState4, amount: currencyAmounts[4] },
    ]
    const { shouldApproveData, pendingApproveData, unknownApproveDate } = approveStates.reduce<{
      [key: string]: {
        state: ApprovalState
        approve: () => Promise<void>
        amount?: CurrencyAmount<Currency>
      }[]
    }>(
      (memo, curr) => {
        const { amount: amount0, state } = curr
        if (!amount0 || !state) {
          return memo
        }

        switch (state) {
          case ApprovalState.NOT_APPROVED:
            const address = amount0.currency.wrapped.address
            const amount1 = balanceMap[address]
            if (!amount1) break

            const bigAmount0 = new BigFloatNumber(amount0.toExact())
            const bigAmount1 = new BigFloatNumber(amount1.toExact())

            // check balance whether or not enough
            if (bigAmount0.gt(bigAmount1)) break
            memo['shouldApproveData'].push(curr)
            break
          case ApprovalState.PENDING:
            memo['pendingApproveData'].push(curr)
            break
          case ApprovalState.UNKNOWN:
            if (!curr.amount) break

            memo['unknownApproveDate'].push(curr)
            break

          default:
            break
        }

        return memo
      },
      { shouldApproveData: [], pendingApproveData: [], unknownApproveDate: [] }
    )
    return {
      shouldApproveNum: shouldApproveData.length,
      shouldApproveData,
      pendingApproveNum: pendingApproveData.length,
      pendingApproveData,
      unknownApproveNum: unknownApproveDate.length,
      unknownApproveDate,
      approveStates,
    }
  }, [
    approve0,
    approve1,
    approve2,
    approve3,
    approve4,
    approveState0,
    approveState1,
    approveState2,
    approveState3,
    approveState4,
    balanceMap,
    currencyAmounts,
  ])

  const onApproveHandler = useCallback(async () => {
    if (!shouldApproveData) return

    setAllPening(true)

    const len = shouldApproveData.length
    try {
      for (let i = 0; i < len; ++i) {
        const { approve } = shouldApproveData[i]
        await approve()
      }
    } catch (error) {
      setAllPening(false)
    }

    // setAllPening(false)
  }, [shouldApproveData])

  useEffect(() => {
    if (shouldApproveNum == 0 && AllPening && !pendingApproveNum) {
      setAllPening(false)
    }
  }, [AllPening, pendingApproveNum, shouldApproveNum])

  const [isApproved, approveError] = useMemo(() => {
    let error: string | undefined

    const ans = !shouldApproveNum && !unknownApproveNum && !pendingApproveNum

    if (unknownApproveNum && unknownApproveNum > 0) {
      error = t`Unknown approve`
    }

    if (!ans) {
      error = error ?? t`Please approve`
    }

    return [ans, error]
  }, [pendingApproveNum, shouldApproveNum, unknownApproveNum])

  const [hasUnknown] = useMemo(() => {
    const hasUnknown = !!unknownApproveNum && unknownApproveNum > 0

    return [hasUnknown]
  }, [unknownApproveNum])

  const DynamicApprove: any = useCallback(
    () => (
      <>
        {(!!pendingApproveNum && !shouldApproveNum) || AllPening ? (
          <ButtonPrimary disabled variant="blueViolet" style={{ width: '100%' }}>
            <Pending />
          </ButtonPrimary>
        ) : (
          !!shouldApproveNum && (
            <ButtonConfirmed variant="blueViolet" style={{ width: '100%' }} onClick={() => onApproveHandler()}>
              <Trans>Approve</Trans>({shouldApproveNum})
            </ButtonConfirmed>
          )
        )}
      </>
    ),
    [AllPening, onApproveHandler, pendingApproveNum, shouldApproveNum]
  )

  return useMemo(() => {
    return {
      DynamicApprove,
      isApproved,
      approveError,
      hasUnknown,
    }
  }, [DynamicApprove, approveError, hasUnknown, isApproved])
}
