import { serializeError } from 'eth-rpc-errors'
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { SerializedEthereumRpcError } from 'eth-rpc-errors/dist/classes'

const jsonError: any = {
  // "RoxSpotPool": "----------------------------------------------------------------",
  xpp: 'Only permited by authorized roxPerpRouter',
  xmgr: 'Only permited by authorized roxPosionManager',
  ai: 'Pool not initialized',
  t6: 'tick spacing must be 600',
  m0: 'Token0 transferred in not enough',
  m1: 'Token1 transferred in not enough',
  bn0: 'Liquidity(Token0) is being utilized by perpetual trading.',
  bn1: 'Liquidity(Token1) is being utilized by perpetual trading.',
  AS: 'swap amount is 0',
  SPL: 'Out of liquidity to swap',
  IS: 'Insufficient liquidity to swap',
  IIA: 'system error',
  t0s: 'Liquidity(Token0) is being utilized by perpetual trading.',
  t1s: 'Liquidity(Token1) is being utilized by perpetual trading.',

  // "RoxPerpPool": "----------------------------------------------------------------",
  uf: 'PerpPosition accumulated fee is larger than collateral',
  sd: 'Only permited by authorized roxRouter',
  ds: 'Size delta is larger than position size, please decrease size delta',
  dc: 'Collateral delta is larger than position collateral, please decrease collateral delta',
  cmd: 'Collateral delta is larger than position collateral, please decrease collateral delta',
  fcp: 'Collateral delta is larger than position collateral, please decrease collateral delta',
  t0p: "Due to excessive utilization of liquidity in perpetual trading, it's not possible to add to the position. (Token0)",
  t1p: "Due to excessive utilization of liquidity in perpetual trading, it's not possible to add to the position. (Token1)",
  rv0: 'Perp Reserve0 is smaller than reserve delta',
  rv1: 'Perp Reserve1 is smaller than reserve delta',
  c: 'liquidity for perp settle is not enough',
  tp: 'token amount should larger than profit',

  // "RoxPosnPool": "----------------------------------------------------------------",
  xp: 'not authorized to update tick price',
  bol: "The decreased liquidity is greater than the user's specified liquidity value.",

  // "PerpRouter": "----------------------------------------------------------------",
  ol: 'not authorized to set',
  z0i: 'specified token amount should larger than 0',
  nlq: 'The liquidation condition has not been met.',
  np0: 'The take-profit condition has not been reached.(0)',
  np1: 'The take-profit condition has not been reached.(1)',
  nps: 'The position is not in the take-profit queue.',
  esp: "The executed price spread is larger than the user's setting.",
  irp: 'only permited by position holder',
  npp: 'not approved perp pool',

  //"hyper": "----------------------------------------------------------------",
  oF: 'only permited by rox factory',
  id: 'token0 is same as token1',
  za: 'token0 empty address',
  np: 'pool already created',
  ow: 'only permited by owner',
  to: 'invalid destination address',
  nz0: 'The deposited amount for Token0 must be larger than zero.',
  nz1: 'The deposited amount for Token1 must be larger than zero.',
  s0: 'withdraw share amount must be larger than zero.',
  ifs: 'withdraw share amount must be smaller than user hold.',
  nr: 'The ratio of the deposit amount for Token0 to Token1 must be the same as the pool ratio.',
  alocked: 'amount has been locked',

  // "roxutils": "----------------------------------------------------------------",
  lock: 'spot pool locked.',
  cs1: 'position size must larger than collateral',
  pec: 'position collateral must larger than zero',
}

export const handlerError = (error: any) => {
  const rpcError: SerializedEthereumRpcError = serializeError(error)
  // @ts-ignore
  if (rpcError.data || rpcError.data.originalError) {
    const errs = rpcError.data as any
    const msgError = errs.originalError.reason
    if (msgError == 'execution reverted: err spread') {
      return 'Transaction Failed: Price Spread Exceeds MaxSlippage Setting.'
    }
    const temperr: string = msgError.replace('execution reverted: ', '')
    if (jsonError[temperr]) return jsonError[temperr]

    return msgError
  } else {
    return rpcError.message
  }
}
