import styled from 'styled-components/macro'

import { BN } from './bn'

const BoxSpan = styled.span`
  display: inline-flex;
  span {
    font-size: 10px;
    display: inline;
    padding-inline-end: 4px;
    padding-inline-start: 4px;
    margin-top: 8px;
    zoom: 0.8;

    font-weight: 700;
  }
`

const BoxTSpan = styled.tspan`
  display: inline-flex;
  tspan {
    font-size: 10px;
    display: inline;
    padding-inline-end: 4px;
    padding-inline-start: 4px;
    margin-top: 8px;
    zoom: 0.8;

    font-weight: 700;
  }
`

export const countZeros = (num?: string | number, tostring?: boolean,isTspan?:boolean) => {
  if (!num) return '0'
  const str = num.toString()
  const arr = str.split('.')
  if (arr.length === 1) {
    return num
  }
  if (Number(arr[0]) > 100) return BN(num).toFixed(2, 3)
  if (!arr[1] || arr[1].length == 0) return num

  const arrstr: any = arr[1]
  let zeroLen = 0

  let endNum

  for (const i in arrstr) {
    if (Number(arrstr[i]) === 0) {
      zeroLen++
    } else {
      endNum = arrstr.substring(i, arrstr.length)
      break
    }
  }

  const filt = new Array(zeroLen).fill(0).join('')
  if (zeroLen < 4) return `${arr[0]}.${filt}${Number(arrstr.substring(0, 4))}`

  const strHtml = `${arr[0]}.0 <span >${zeroLen}</span> ${endNum.substring(0, 4)}`
  if (tostring) {
    return `${arr[0]}.0 ${zeroLen} ${endNum.substring(0, 4)}`
  }
  if(isTspan){
    const strT = `${arr[0]}.0<tspan>${zeroLen}</tspan>${endNum.substring(0, 4)}`
    return <BoxTSpan dangerouslySetInnerHTML={{ __html: strT }}></BoxTSpan>
  }
  return <BoxSpan dangerouslySetInnerHTML={{ __html: strHtml }}></BoxSpan>
}
