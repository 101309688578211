import { atom, useAtom } from 'jotai'
import { useCallback } from 'react'

export enum MarketSortMethod {
  INFO = 'Info',
  Liquidity = 'Liquidity',
  Marketcap = 'Marketcap',
  Participants = 'Participants',
  CreatedIn = 'Created',
  Description = 'Description',
}

export const sortMethodAtom = atom<MarketSortMethod>(MarketSortMethod.Participants)
export const sortAscendingAtom = atom<boolean>(false)
export const sortPageAtom = atom<number>(1)

/* keep track of sort category for token table */
export function useSetSortMethod(newSortMethod: MarketSortMethod) {
  const [sortMethod, setSortMethod] = useAtom(sortMethodAtom)
  const [sortAscending, setSortAscending] = useAtom(sortAscendingAtom)

  return useCallback(() => {
    if (sortMethod === newSortMethod) {
      setSortAscending(!sortAscending)
    } else {
      setSortMethod(newSortMethod)
      setSortAscending(false)
    }
  }, [sortMethod, setSortMethod, setSortAscending, sortAscending, newSortMethod])
}
