import { ChainId, SUPPORTED_CHAINS, SupportedChainsType } from './chains'

type AddressMap = { [chainId: number]: string }

type ChainAddresses = {
  factoryAddress: string
  multicallAddress: string
  quoterAddress: string
  orderBookAddress?: string
  nonfungiblePositionManagerAddress?: string
  tickLensAddress?: string
  swapRouterAddress?: string
  v1MixedRouteQuoterAddress?: string
  lockAddress?: string
  tradeRouter?: string
  voterAddress?: string
  rougexTokenAddress?: string
  faucetAddress?: string
  minterAddress?: string
  tradeReaderAddress?: string
  rewardsDistributorAddress?: string
  roxUtilsAddress?: string
  PosnReader?: string
  Launchpad?: string
  TokenFactory?: string
  BaseStaking?: string
  BaseToken?: string
}

const DEFAULT_NETWORKS = [ChainId.ARBITRUM_ONE]

function constructSameAddressMap(address: string, additionalNetworks: ChainId[] = []): AddressMap {
  return DEFAULT_NETWORKS.concat(additionalNetworks).reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = address
    return memo
  }, {})
}

const ARBITRUM_ONE_ADDRESSES: ChainAddresses = {
  factoryAddress: '0x2140c5B4ef9Cc6d18196C43eeFA9dfF3595467A2',
  tradeRouter: '0xBCcDa22DB89AA35Eff735c433AE0f2642dd3Da07',
  nonfungiblePositionManagerAddress: '0x5Cf3679187B6096a990393bBE67Ec191e3fD7416',
  swapRouterAddress: '0x064d6286b4A9702a85e934AECac2407eb509F34a',
  quoterAddress: '0xA13f5A14c384F550c7168453c1EE0B30F10dc4d0',
  tickLensAddress: '0xE58E92B0A47F29c38A919527099B4d5f7eff6d43',

  roxUtilsAddress: '0x586536752bD0d3D767440E73FdBa2D4016990E2F',
  tradeReaderAddress: '0xE48f2C155e4Cc7D7d2aFBEEc11ce85088e7d5D90',
  orderBookAddress: '0x9D0DF48c859942cFd437F1A5e8e5f8980118e445',

  lockAddress: '0xCD3B42917b4d1f896F0735C1D6EDd44304548Fa4',

  rougexTokenAddress: '0x1F4D8C5f5cb85E923Bff7D73bf08795E68d4b648',
  rewardsDistributorAddress: '0x0d0E982AE77DE7bcd59f9Bd9bf19BBa6d2068964',

  voterAddress: '0x03534749642F4545233CCFDA3c1074fDc8a5511e',
  minterAddress: '0xF73e7422fBBdB4CB4252D0eF3724D9eb6967Aca3',

  multicallAddress: '0x027EDCF231957833e95Bf6131E61EA8345d580d3',

  faucetAddress: '0xc99ba80D87c156b90c8AEEe4fa33658df14E14F5',
}

// eslint-disable-next-line multiline-comment-style
// arbitrum goerli v3 addresses
const GOERLI_ADDRESSES: ChainAddresses = {
  factoryAddress: '0x0149Ae85D16f396A8cc34445876a304097910639',
  tradeRouter: '0xe32A514D929Daa0A9b3853C71bD0e22d80679CBA',
  nonfungiblePositionManagerAddress: '0xDC3e6cA428F36EEfBb6eE2c40AD2E84FDEe2Db19',
  swapRouterAddress: '0x63238B8e910288f6aB3C8Eebef207d0C7b12b66e',
  quoterAddress: '0x4082541102D020f5aC0a9677E2d7dcAa0BcCB631',
  tickLensAddress: '0x6f91826dAb2aFFD602A97EFeAc8Ba99FA9226062',
  roxUtilsAddress: '0xb92C858f8208C82bE209d347b378BF7d63eBb15F',
  tradeReaderAddress: '0xD4f02851F730e8Ad23560C8d02236E7904885D43',
  orderBookAddress: '0xd95E11E8288F94D5E7eF14DB3f7a7F4D85b5b6fD',

  multicallAddress: '0x1F98415757620B543A52E61c46B32eB19261F984',

  lockAddress: '0x47Cd8AAae6AaDa375AB712bD2340aBD05d403566',
  rougexTokenAddress: '0x3d3fADCbeba5e68a35581681b6a2D650DAd292f4',
  rewardsDistributorAddress: '0x7F80E24A4C482584605c592908fe3278732F5838',

  voterAddress: '0x86395AB62F130E77D1CF237C6d6EBa8F6A7DDc3d',
  minterAddress: '0x55DF366DAb41B8207c7060b9ae67D257bB9A655D',

  faucetAddress: '0xc99ba80D87c156b90c8AEEe4fa33658df14E14F5',
}

const Sepolia_ADDRESSES: ChainAddresses = {
  factoryAddress: '0xa55F2744DA15cD95822E8e0958305C58e32F9a98',
  tradeRouter: '0xE84594F5B8740E428405f6928C493ef63A318dbA',
  nonfungiblePositionManagerAddress: '0x0Fe7ACf5a758144536e415540e560650B79F6214',
  swapRouterAddress: '0xf8D61E10845893c12B9C495e01976C43F957b529',
  quoterAddress: '0x86eFaA010f65B17dD7504fA7450d56aEcC77bcAD',
  tickLensAddress: '0x4Eb761FCdEC79825b50d7572AdC02601b453639b',
  roxUtilsAddress: '0x75B801637DBB14E59B6Bf378b558601B7B219057',
  tradeReaderAddress: '0xF18F2234823130C50EeD4736893a328Ab4ab54D0',
  orderBookAddress: '0x06422f49ab551c6D7e1ad30af4B7A80f5712d13d',

  multicallAddress: '0x532a5B1A2F8ccE4f05F8b2a9A262c7097B4356ec',

  lockAddress: '0x48878653673113bD4DbEd485a34E194735BA7E10',
  rougexTokenAddress: '0x00fA700a16eF7B3c968Bb1dCD4C6bA697bA73fb9',
  rewardsDistributorAddress: '0x20B7ab1AD1517Bd17cD2F4dc5C29920Bff92ba82',

  voterAddress: '0x780A9Fa3B434b99A2f7751B00c6fC269f19871Bd',
  minterAddress: '0x918891120A62B0F2a91012a8De9f10Dc30a9E3Cd',

  faucetAddress: '0xc99ba80D87c156b90c8AEEe4fa33658df14E14F5',
}
/*
BaseStaking: 0xe41FC6Bb076d20fd1E4906d86f04C5024E9871eC
LFGMinter: 0xebd943B1fA8dE0b032097A9F1b0E21B08A1F7493
LFGTokenContract: 0x642f9fe72300986Fe978E1b077d4Af2a15c82D3f
RewardFactoryContract: 0x9b0371F19F6CcE39EA8F509861Af1C1229c37251
LaunchpadContract: 0x7987aD3B6A896e372c212554713E70232e7A92a2
TokenFactory: 0x2122aB07e0be2088979b0A965215b7e4DB7Aa70A
*/
const BLAST_TEST_ADDRESSES: ChainAddresses = {
  factoryAddress: '0xD9D07283566cDf97437faD2eeeE7F7Ca95dc799f',
  tradeRouter: '0xF8FDfb0074aE187e48aC55332750dCF825a2a046',
  nonfungiblePositionManagerAddress: '0x3B13e91AB27383aBf239bfA1bE4F69c663D54161',
  swapRouterAddress: '0x9f0359492A8dAfb9C5bfbE3246fD874817A9b7ba',
  quoterAddress: '0x3A292939e8151C3CB7A85c738Ee0497dce8ecfbC',
  tickLensAddress: '0x5e62b3aCAa4d892e8696e2dddf573648207Db4B5',
  roxUtilsAddress: '0x2B2A893BdF764C1B0F06D2C59ECDBf7291b10d1C',
  tradeReaderAddress: '0x6209b245D1378B59dEb361BA0132DEAc81007aB6',
  orderBookAddress: '0x53D6aBE3B585E6b491D354b8098f6b1611Aa7F52',

  multicallAddress: '0xB2f67eA43943e6C14580B9ECc88B3b05969eD4b3',

  lockAddress: '0xbD0c3e5cc7aBc6033f9E7C96d603278A0D5929A2',
  rougexTokenAddress: '0x91Da16c662ff307dE7A80C4D8B95b1fA866e50af',
  rewardsDistributorAddress: '0x76A8d84aBeCFf68a53a7014763BE9623990b64E5',

  voterAddress: '0xE3Ddfc574fA73ae369541c1303ae2Fce8AB292ee',
  minterAddress: '0x6E61230570F1600403aaD50b6a72ec0f1a53C031',
  PosnReader: '0xf4bF098950Fb8F603203B78714fA0F8B2A7041b9',
  faucetAddress: '0x2516F4Cb4De50CC62B8705d4067C42b451b5A36c',

  Launchpad: '0x7987aD3B6A896e372c212554713E70232e7A92a2',
  TokenFactory: '0x2122aB07e0be2088979b0A965215b7e4DB7Aa70A',
  BaseStaking: '0xe41FC6Bb076d20fd1E4906d86f04C5024E9871eC',
  BaseToken: '0x642f9fe72300986Fe978E1b077d4Af2a15c82D3f',
}

const BLAST_MAIN_ADDRESSES: ChainAddresses = {
  factoryAddress: '0xbd9215e002E4344c8333fc0bf4F5ECEd62BF9B85',
  tradeRouter: '0x090787f79911b19C8DbF1261Abe4FF5b4B833Bd7',
  nonfungiblePositionManagerAddress: '0x8632A0b0095f6c6D51A03E245D93B25eD2b6F709',
  swapRouterAddress: '0xc8CBb74fc79F85d1Df0aB89D5a7b6766D237EbfC',
  quoterAddress: '0x88332d6e6aD87e2C72BF04c04a5057947D438885',
  tickLensAddress: '0xf0ca239Cbb24F33Fb92A9dB1D47e62feD6cDa8a4',
  roxUtilsAddress: '0xc9bCbF1Bb96c5b19513aF64464dEa8C69c790369',
  tradeReaderAddress: '0xb81B69DC539eD055d0547aAb96dA7D1BaeC4D7Ad',
  PosnReader: '0xBb731A4d6C563e8A48871479511930F98C37e6d4',
  orderBookAddress: '0x18aa46961E952812C62B9Ffc9aA7780AAAdb3826',

  multicallAddress: '0xdf119e932877E61e5C2DB627C70130D37E45814C',

  lockAddress: '0x717aA1606F1891F9Dc124381a9ba16B51FFCF396',
  rougexTokenAddress: '0x93E069A379b1Db74C10635893AbeC755CB8fD62e',
  rewardsDistributorAddress: '0xD4D589602441eCd9833CB1e05e542474D4c95512',

  voterAddress: '0x21F758179a1e40007234B6f3B0688d135864Caf8',
  minterAddress: '0xd702783F90e4573B2906b558B625b53ceDD72542',

  faucetAddress: '0x2516F4Cb4De50CC62B8705d4067C42b451b5A36c',
  Launchpad: '0x659A1d0B7cF7bc5021a42BE436E42209965c6941',
  TokenFactory: '0xceAEF3bc0188BFeb1Cc696c725Eda041322AB7CF',
}
const ZIRCUIT_TEST_ADDRESSES: ChainAddresses = {
  factoryAddress: '0xdf119e932877E61e5C2DB627C70130D37E45814C',
  tradeRouter: '0x859997D5686486b023b4fC375f6356FEDf40A923',
  nonfungiblePositionManagerAddress: '0x386d7bE5793B061F01601F606f28E1017Cbc9E68',
  swapRouterAddress: '0xdD84404e9741fB1Ba2f52906B25126fAE22b4243',
  quoterAddress: '0x37e33B250985FAf4DBdc607FA7454CA76B883A2a',
  tickLensAddress: '0xE8480B6bF4d0b5B2BE95cc41eCC14a98d528215b',
  roxUtilsAddress: '0x5B0b4b97edb7377888E2c37268c46E28f5BD81d0',
  tradeReaderAddress: '0xcd16b287536FF7A761B3574066a91e95635481ec',
  PosnReader: '0xee75742daFE25220337009949c0C529f56904151',
  orderBookAddress: '0x8e7a5187117F3dfbae028ac26db00A75aE41F095',

  multicallAddress: '0xf26Bd9BA435395F26634C9Be5b717C6d10675897',

  lockAddress: '0xd4c4Ae8172253332F4D5E13aAFC096cF35f33905',
  rougexTokenAddress: '0x05956a4674945DEE1E00442a53c562350282C340',
  rewardsDistributorAddress: '0xE962D867D5b6b06b9c1a68A625d2ea44b4378D21',

  voterAddress: '0x03245900162B798726514d9c3c18c1C8EfF2952f',
  minterAddress: '0x5506Fa71d84585acD4A06AD5bFcb1e5F6d876967',

  Launchpad: '0x00bf1383E87A1FCcC40230E72862d117B0340ffF',
  TokenFactory: '0x8a5310FEB1DE24C427C489394980F4caA9fD2795',
  BaseStaking: '0x64fF1b3797bd606cd90362eCB59E9dFA97e60E5b',
  BaseToken: '0x6d3b2a261D3635e438F20484855bC10eA309B09B',
}

const ZIRCUIT_MAIN_ADDRESSES: ChainAddresses = {
  factoryAddress: '0x5B0b4b97edb7377888E2c37268c46E28f5BD81d0',
  tradeRouter: '0xdD84404e9741fB1Ba2f52906B25126fAE22b4243',
  nonfungiblePositionManagerAddress: '0x37e33B250985FAf4DBdc607FA7454CA76B883A2a',
  swapRouterAddress: '0x8e7a5187117F3dfbae028ac26db00A75aE41F095',
  quoterAddress: '0xE8480B6bF4d0b5B2BE95cc41eCC14a98d528215b',
  tickLensAddress: '0x859997D5686486b023b4fC375f6356FEDf40A923',
  roxUtilsAddress: '0x9C6C26F5D308B22254a57a87744E0c018BE20dC7',
  tradeReaderAddress: '0xee75742daFE25220337009949c0C529f56904151',
  PosnReader: '0xFE4F65b03D02944995B31Dc645A2a33021111DF8',
  orderBookAddress: '0x648AA4e686F538e77EF0DEba5362009483EDC5cb',

  multicallAddress: '0xdf119e932877E61e5C2DB627C70130D37E45814C',

  lockAddress: '0xE962D867D5b6b06b9c1a68A625d2ea44b4378D21',
  rougexTokenAddress: '0xf26Bd9BA435395F26634C9Be5b717C6d10675897',
  rewardsDistributorAddress: '0x84f7Df1129B8cD21ab4920BE6EaD959E33Ed6b86',

  voterAddress: '0x5506Fa71d84585acD4A06AD5bFcb1e5F6d876967',
  minterAddress: '0x7F4701CF364F0481d37272590CbB5F809C8c3B0F',

  Launchpad: '0xCD3B42917b4d1f896F0735C1D6EDd44304548Fa4',
  TokenFactory: '0x0d0E982AE77DE7bcd59f9Bd9bf19BBa6d2068964',
  BaseStaking: '0x03534749642F4545233CCFDA3c1074fDc8a5511e',
  BaseToken: '0xc0D575d2CaAE78F6938b67501f864aeD87f160dE',
}

const CHAIN_TO_ADDRESSES_MAP: Record<SupportedChainsType, ChainAddresses> = {
  [ChainId.ARBITRUM_ONE]: ARBITRUM_ONE_ADDRESSES,
  [ChainId.GOERLI]: GOERLI_ADDRESSES,
  [ChainId.ARBITRUM_SEPOLIA]: Sepolia_ADDRESSES,
  [ChainId.BLAST_TEST]: BLAST_TEST_ADDRESSES,
  [ChainId.BLAST_MAIN]: BLAST_MAIN_ADDRESSES,
  [ChainId.ZIRCUIT_TEST]: ZIRCUIT_TEST_ADDRESSES,
  [ChainId.ZIRCUIT_MAIN]: ZIRCUIT_MAIN_ADDRESSES,
}

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].factoryAddress
    return memo
  }, {}),
}

export const MULTICALL_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].multicallAddress
    return memo
  }, {}),
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {}

export const QUOTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].quoterAddress
    return memo
  }, {}),
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const nonfungiblePositionManagerAddress = CHAIN_TO_ADDRESSES_MAP[chainId].nonfungiblePositionManagerAddress
    if (nonfungiblePositionManagerAddress) {
      memo[chainId] = nonfungiblePositionManagerAddress
    }
    return memo
  }, {}),
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'),
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tickLensAddress = CHAIN_TO_ADDRESSES_MAP[chainId].tickLensAddress
    if (tickLensAddress) {
      memo[chainId] = tickLensAddress
    }
    return memo
  }, {}),
}
export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const swapRouter02Address = CHAIN_TO_ADDRESSES_MAP[chainId].swapRouterAddress
    if (swapRouter02Address) {
      memo[chainId] = swapRouter02Address
    }
    return memo
  }, {}),
}
export const TRADE_ROUTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tradeRouter = CHAIN_TO_ADDRESSES_MAP[chainId].tradeRouter
    if (tradeRouter) {
      memo[chainId] = tradeRouter
    }
    return memo
  }, {}),
}

export const LOCK_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const lockAddress = CHAIN_TO_ADDRESSES_MAP[chainId].lockAddress
    if (lockAddress) {
      memo[chainId] = lockAddress
    }
    return memo
  }, {}),
}

export const VOTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const voterAddress = CHAIN_TO_ADDRESSES_MAP[chainId].voterAddress
    if (voterAddress) {
      memo[chainId] = voterAddress
    }
    return memo
  }, {}),
}

export const MINTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const minterAddress = CHAIN_TO_ADDRESSES_MAP[chainId].minterAddress
    if (minterAddress) {
      memo[chainId] = minterAddress
    }
    return memo
  }, {}),
}

export const ROUGEX_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const rougexTokenAddress = CHAIN_TO_ADDRESSES_MAP[chainId].rougexTokenAddress
    if (rougexTokenAddress) {
      memo[chainId] = rougexTokenAddress
    }
    return memo
  }, {}),
}

export const FAUCET_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const faucetAddress = CHAIN_TO_ADDRESSES_MAP[chainId].faucetAddress
    if (faucetAddress) {
      memo[chainId] = faucetAddress
    }
    return memo
  }, {}),
}
export const TRADEREADER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tradeReaderAddress = CHAIN_TO_ADDRESSES_MAP[chainId].tradeReaderAddress
    if (tradeReaderAddress) {
      memo[chainId] = tradeReaderAddress
    }
    return memo
  }, {}),
}

export const REWARDS_DISTRIBUTOR_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const rewardsDistributorAddress = CHAIN_TO_ADDRESSES_MAP[chainId].rewardsDistributorAddress
    if (rewardsDistributorAddress) {
      memo[chainId] = rewardsDistributorAddress
    }
    return memo
  }, {}),
}

export const ROX_UTILS_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const roxUtilsAddress = CHAIN_TO_ADDRESSES_MAP[chainId].roxUtilsAddress
    if (roxUtilsAddress) {
      memo[chainId] = roxUtilsAddress
    }
    return memo
  }, {}),
}

export const ORDER_BOOK_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const bookAddress = CHAIN_TO_ADDRESSES_MAP[chainId].orderBookAddress
    if (bookAddress) {
      memo[chainId] = bookAddress
    }
    return memo
  }, {}),
}

export const POSN_READER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const bookAddress = CHAIN_TO_ADDRESSES_MAP[chainId].PosnReader
    if (bookAddress) {
      memo[chainId] = bookAddress
    }
    return memo
  }, {}),
}

export const Launchpad_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const bookAddress = CHAIN_TO_ADDRESSES_MAP[chainId].Launchpad
    if (bookAddress) {
      memo[chainId] = bookAddress
    }
    return memo
  }, {}),
}
export const TokenFactory_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const bookAddress = CHAIN_TO_ADDRESSES_MAP[chainId].TokenFactory
    if (bookAddress) {
      memo[chainId] = bookAddress
    }
    return memo
  }, {}),
}

export const BaseStaking_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const bookAddress = CHAIN_TO_ADDRESSES_MAP[chainId].BaseStaking
    if (bookAddress) {
      memo[chainId] = bookAddress
    }
    return memo
  }, {}),
}

export const BaseToken_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const bookAddress = CHAIN_TO_ADDRESSES_MAP[chainId].BaseToken
    if (bookAddress) {
      memo[chainId] = bookAddress
    }
    return memo
  }, {}),
}
