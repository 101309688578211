import { useActiveChainId } from 'connection/useActiveChainId'
import { BaseToken_ADDRESSES } from 'constants/addresses'
import { BigNumber } from 'ethers'
import { useMemeStakingContract, useV3PoolContract } from 'hooks/useContract'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useMemo } from 'react'
import { useHttpRoxPrice } from 'state/http/hooks'
import { BN, fromWei } from 'utils/bn'
export const usePoolSqrtRatioX96 = (poolAddress: string) => {
  const v3PoolContract = useV3PoolContract(poolAddress)

  const { result: slot0 } = useSingleCallResult(v3PoolContract, 'slot0')

  const currentSqrtRatioX96: undefined | BigNumber = useMemo(() => slot0?.[0], [slot0])

  return currentSqrtRatioX96
}

export const useStakedMeme = (tokenAddress: string, stakingAddress: string) => {
  const { account } = useActiveChainId()

  const StakingContract = useMemeStakingContract(stakingAddress)

  const { result } = useSingleCallResult(StakingContract, 'balanceOf', [account])
  return useMemo(() => {
    if (!result) return
    return fromWei(result[0].toString())
  }, [result])
}

export const useRewardsMeme = (stakingAddress: string) => {
  const StakingContract = useMemeStakingContract(stakingAddress)

  const { account, chainId } = useActiveChainId()

  const { result } = useSingleCallResult(StakingContract, 'earned', [BaseToken_ADDRESSES[chainId], account])

  return useMemo(() => {
    if (!result) return
    return fromWei(result[0].toString())
  }, [result])
}

export const useApr = (stakingAddress: string) => {
  const StakingContract = useMemeStakingContract(stakingAddress)
  const roxPrice = useHttpRoxPrice()
  const { chainId } = useActiveChainId()

  const { result: rewardsResult } = useSingleCallResult(StakingContract, 'rewardRate', [BaseToken_ADDRESSES[chainId]])

  const { result: totalStakedResult } = useSingleCallResult(StakingContract, 'totalSupply')

  return useMemo(() => {
    if (!rewardsResult || !totalStakedResult || !roxPrice) return
    // console.log(
    //   'rewards',
    //   fromWei(rewardsResult[0].toString()).toString(),
    //   fromWei(totalStakedResult[0].toString()).toString(),
    //   roxPrice
    // )
    return fromWei(rewardsResult[0].toString())
      .times(BN(365))
      .times(BN(86400))
      .times(BN(roxPrice))
      .div(fromWei(totalStakedResult[0].toString()).times(BN(roxPrice)))
      .times(BN(100))
  }, [rewardsResult, roxPrice, totalStakedResult])
}
