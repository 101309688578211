export const colors = {
  // rogue color start
  white: '#FFFFFF',
  white87: 'rgba(255, 255, 255, 0.87)',
  white54: 'rgba(255, 255, 255, 0.54)',
  white38: 'rgba(255, 255, 255, 0.38)',
  white5: 'rgba(255, 255, 255, 0.5)',
  white2: 'rgba(255, 255, 255, 0.2)',
  white25: 'rgba(255, 255, 255, 0.25)',
  white16: 'rgba(255, 255, 255, 0.16)',
  white1: 'rgba(255, 255, 255, 0.1)',
  white08: 'rgba(255, 255, 255, 0.08)',
  white05: 'rgba(255, 255, 255, 0.05)',
  black: '#000000',
  black87: 'rgba(0, 0, 0, 0.87)',
  black54: 'rgba(0, 0, 0, 0.54)',
  black38: 'rgba(0, 0, 0, 0.38)',
  black5: 'rgba(0, 0, 0, 0.5)',
  black2: 'rgba(0, 0, 0, 0.2)',
  black25: 'rgba(0, 0, 0, 0.25)',
  black16: 'rgba(0, 0, 0, 0.16)',
  black1: 'rgba(0, 0, 0, 0.1)',
  black08: 'rgba(0, 0, 0, 0.08)',
  black05: 'rgba(0, 0, 0, 0.05)',
  chartblack54: 'rgba(255, 255, 255, 0.54)',
  chartblack87: 'rgba(255, 255, 255, 0.87)',
  hoverBg: 'linear-gradient(270deg, rgba(9, 35, 6, 0.1) 0.76%, rgba(20, 182, 0, 0.101961) 48.6%, rgba(0, 126, 73, 0.101961) 117.5%)',
  hoverDarkBg: 'linear-gradient(270deg, rgba(9, 35, 6, 0.3) 0.76%, rgba(20, 182, 0, 0.3) 48.6%, rgba(0, 126, 73, 0.3) 117.5%)',
  textBlue: '#73D5FF',
  disabledText: 'rgba(115,213,255,0.38)',
  inputBg_dark: 'rgba(1, 1, 1, 0.10)',
  increaseBg: 'rgba(17,58,81,0.8)',
  connectBg_light: '#007C20',
  connectBg_dark: '#007C20',
  dropBg_light: 'radial-gradient(50% 50% at 50% 50%, #F0EADA 0%, #FFFBEC 100%)',
  dropBg_dark: 'radial-gradient(50% 50% at 50% 50%, #00280D 0%, #001E12 100%)',
  disabledColor_light: '#BFE6F6',
  disabledColor_dark: '#003349',
  rewardedColor: '#0093D1',
  agreeBg_light: '#f6eec4',
  agreeBg_dark: '#996500',
  addAgreeBg: '#FF0000',
  color84: '#848484',
  color06: ' #060606',
  colorE9: '#E9E9E9',
  colorF0: '#F0F0F0',
  colorD9: '#D9D9D9',
  color32: '#323232',
  color16: '#161616',
  color28: '#282828',
  pink500: '#C41969',
  tradeGreen: 'rgba(0, 209, 209, 1)',
  tradeRed: 'rgba(255, 64, 22, 1)',
  tabBgActive: 'linear-gradient(180deg, #50C8FF 0%, #01CED2 100%)',
  menuActive: 'rgba(0, 178, 255, 0.20)',
  moreColor: '#01CED2 ',
  activeBg: '#007C20',
  previewBg: '#EEEEEE',
  primaryBtnBg: '#007C20',
  badgeGradient: 'linear-gradient(180deg, rgba(30, 167, 228, 0.2) 0%, rgba(1, 187, 190, 0.2) 100%);',
  maxBtnBg: 'linear-gradient(180deg, rgba(30, 167, 228, 0.2) 0%, rgba(1, 187, 190, 0.2) 100%)',
  removeBg_light: '#CA8A0D',
  removeBg_dark: '#CA8A0D',
  success: '#0AD5BD',
  fail: '#FF4016',
  retry: '#E44E1E',
  successBg: 'rgba(0, 208, 156, 0.08)',
  failBg: 'rgba(228, 90, 30, 0.08)',
  pendingBg: 'rgba(30, 167, 228, 0.08)',
  short: '#FF3D00',
  long: '#04AE3E',
  transparent: 'transparent',
  blast: '#FCFC00',

  attention_bg_light: '#E4E4E4',
  attention_bg_dark: '#000000',

  primaryBtn_light: '#00BDC4',
  primaryBtn_dark: '#00BDC4',
  primaryBtn_hover_light: '#30E5EC',
  primaryBtn_hover_dark: '#30E5EC',
  primaryBtn_disabled_light: '#008E93',
  primaryBtn_disabled_dark: '#008E93',
  secondaryBtn_border_light: '#007C20',
  secondaryBtn_border_dark: '#00BDC4',
  claimBtn: 'linear-gradient(270deg, rgba(255, 204, 0, 0.3) 0%, rgba(20, 182, 0, 0.3) 50%, rgba(0, 126, 73, 0.3) 100%)',
  claimBtn_border_light: 'rgba(80, 192, 255, 0.20)',
  // rogue color end

  gray50: '#F5F6FC',
  gray100: '#E8ECFB',
  gray200: '#B8C0DC',
  gray250: '#A6AFCA',
  gray300: '#98A1C0',
  gray350: '#888FAB',
  gray400: '#7780A0',
  gray450: '#6B7594',
  gray500: '#5D6785',
  gray550: '#505A78',
  gray600: '#404A67',
  gray650: '#333D59',
  gray700: '#293249',
  gray750: '#1B2236',
  gray800: '#131A2A',
  gray850: '#0E1524',
  gray900: '#0D111C',
  gray950: '#080B11',
  pink50: '#F9ECF1',
  pink100: '#FFD9E4',
  pink200: '#FBA4C0',
  pink300: '#FF6FA3',
  pink600: '#8C0F49',
  pink700: '#55072A',
  pink800: '#350318',
  pink900: '#2B000B',
  pinkVibrant: '#F51A70',
  red50: '#FAECEA',
  red100: '#FED5CF',
  red200: '#FEA79B',
  red300: '#FD766B',
  red400: '#FA2B39',
  red500: '#C4292F',
  red600: '#891E20',
  red700: '#530F0F',
  red800: '#380A03',
  red900: '#240800',
  redVibrant: '#F14544',
  yellow50: '#F6F2D5',
  yellow100: '#DBBC19',
  yellow200: '#DBBC19',
  yellow300: '#BB9F13',
  yellow400: '#A08116',
  yellow500: '#866311',
  yellow600: '#5D4204',
  yellow700: '#3E2B04',
  yellow800: '#231902',
  yellow900: '#180F02',
  yellowVibrant: '#FAF40A',
  // TODO: add gold 50-900
  gold200: '#EEB317',
  gold400: '#B17900',
  goldVibrant: '#FEB239',
  green50: '#E3F3E6',
  green100: '#BFEECA',
  green200: '#76D191',
  green300: '#40B66B',
  green400: '#209853',
  green500: '#0B783E',
  green600: '#0C522A',
  green700: '#053117',
  green800: '#091F10',
  green900: '#09130B',
  greenVibrant: '#5CFE9D',
  blue50: '#EDEFF8',
  blue100: '#DEE1FF',
  blue200: '#ADBCFF',
  blue300: '#869EFF',
  blue400: '#00B2FF',
  blue500: '#1267D6',
  blue600: '#1D4294',
  blue700: '#09265E',
  blue800: '#0B193F',
  blue900: '#040E34',
  blueVibrant: '#587BFF',
  // TODO: add magenta 50-900
  magenta300: '#FD82FF',
  magentaVibrant: '#FC72FF',
  purple300: '#8440F2',
  purple900: '#1C0337',
  purpleVibrant: '#6100FF',
  // TODO: add all other vibrant variations
  networkEthereum: '#627EEA',
  networkOptimism: '#FF0420',
  networkOptimismSoft: 'rgba(255, 4, 32, 0.16)',
  networkPolygon: '#A457FF',
  networkArbitrum: '#28A0F0',
  networkBsc: '#F0B90B',
  networkPolygonSoft: 'rgba(164, 87, 255, 0.16)',
  networkEthereumSoft: 'rgba(98, 126, 234, 0.16)',
  threshold: '#077CAE',
  removeThreshold: '#00D1D1',

  cumulative: '#00D09C',
  luckyBorder: '#54BAF3',
  referralBorder: '#007C20',
  referralBg: 'rgba(254, 251, 251, 0.05)',
  referralCode_dark: '#F9C16C',
  referralCodeBorder: 'rgba(249, 193, 108, 0.20)',
  referralLinkBg: 'rgba(249, 193, 108, 0.50)',
  referralCodeBg: 'rgba(202, 138, 13, 0.20)',
  referralTitleBg_light: '#EDF6F9',
  postBg: '#007C20',
  postBgHover: '#42C2FB',
}
