import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ReactComponent as FRDIcon } from 'assets/imgs/marketCap/FRD.svg'
import { ReactComponent as ETHIcon } from 'assets/imgs/stake/eth.svg'
import { ReactComponent as ToIcon } from 'assets/imgs/stake/to2.svg'
import { BaseButton } from 'components/Button'
import { useLaunchpadContract, useMemeStakingContract } from 'hooks/useContract'
import { useSingleCallResult } from 'lib/hooks/multicall'
import { useApr } from 'pages/MarketCap/InfoDetail/hooks'
import { useState } from 'react'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN, fromWei } from 'utils/bn'
import { countZeros } from 'utils/countZeros'
import { formatAmount2 } from 'utils/formatAmout'

import StakeDialog from './StakeDialog'
import { StyledRewardsList, StyledToIcon } from './RewardsList'
import { StyledItem, StyledStakeBtn } from './StakedList-m'


function EpochTokenFee({ memeAddress, roundId }: any) {
  const LaunchpadContract = useLaunchpadContract()

  const { result: fee } = useSingleCallResult(LaunchpadContract, 'tokenFee', [roundId * 7200, memeAddress])

  return (
    <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
      {countZeros(fromWei(fee?.toString() || 0).toFixed())}
    </ThemedText.TextPrimary>
  )
}

export default function RewardsListM({ epochList }: any) {
  const [stakedAmount, setStakedAmount] = useState(0)
  const [memeStakingAddress, setMemeStakingAddress] = useState('')
  const [tokenImage_url, setTokenImage_url] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [tokenAddress, setTokenAddress] = useState('')

  const StakingContract = useMemeStakingContract(memeStakingAddress)

  const apr = useApr(memeStakingAddress)

  const [openStake, setOpenStake] = useState(false)
  const handleOpenStakeDialog = () => {
    setOpenStake(true)
  }
  const handleCloseStake = () => {
    setOpenStake(false)
  }

  return (
    <StyledRewardsList>
      {epochList &&
        epochList.map((item: any, i: any) => (
          <StyledItem key={i}>
            <div className="token">
              <Box display="flex" alignItems="center" gap="8px">
                <img src={item.memeToken.metadata.image_url} width="30px" height="30px" />
                <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
                  <Trans>{item.memeToken.symbol}</Trans>
                </ThemedText.TextPrimary>
              </Box>
            </div>
            <div className="info">
              <Box display="flex" alignItems="center" justifyContent="space-between" gap="8px">
                <Box display="flex" flexDirection="column" gap="4px" alignItems="flex-start">
                  <ThemedText.TextSecondary fontSize={14} fontWeight={400}>
                    <Trans>Fees</Trans>
                  </ThemedText.TextSecondary>
                  <Box display="flex" alignItems="center" gap="8px">
                    <ETHIcon width="16px" height="16px" />
                    <EpochTokenFee memeAddress={item.memeToken.id} roundId={item.roundId} />
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" gap="4px" alignItems="flex-end">
                  <ThemedText.TextSecondary fontSize={14} fontWeight={400}>
                    <Trans>Pool's Rewards</Trans>
                  </ThemedText.TextSecondary>
                  <Box display="flex" alignItems="center" gap="8px">
                    <FRDIcon width="16px" height="16px" />
                    <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
                      {formatAmount2(item.amount, 18, 2, true)}
                    </ThemedText.TextPrimary>
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                gap="16px"
                mt="16px"
                onClick={() => {
                  setStakedAmount(item.amount_formatted)
                  setMemeStakingAddress(item.memeStakingAddress)
                  setTokenImage_url(item.memeToken.metadata.image_url)
                  setTokenSymbol(item.memeToken.symbol)
                  setTokenAddress(item.memeToken.id)
                  handleOpenStakeDialog()
                }}
              >
                <StyledStakeBtn className="flex-1">
                  <ThemedText.TextPrimary fontSize={14} fontWeight={700} className="active-gradient-text">
                    <Trans>STAKE</Trans>
                  </ThemedText.TextPrimary>
                </StyledStakeBtn>
              </Box>
            </div>
          </StyledItem>
        ))}

      <StakeDialog
        open={openStake}
        handleClose={handleCloseStake}
        stakedAmount={BN(stakedAmount)}
        apr={apr}
        tokenName={tokenSymbol}
        tokenIcon={tokenImage_url}
        tokenAddress={tokenAddress}
        StakingContract={StakingContract}
      />
    </StyledRewardsList>
  )
}
