import { useActiveChainId, useEthersSigner, useProvider } from 'connection/useActiveChainId'
import { useMemo } from 'react'
import { useAccount } from 'wagmi'

export const useProviderOrSigner = (withSignerIfPossible = true) => {
  const { address, isConnected } = useAccount()
  const { chainId } = useActiveChainId()

  const Signer = useEthersSigner()
  const provider = useProvider({ chainId })

  return useMemo(
    () => (withSignerIfPossible && address && isConnected && Signer ? Signer : provider),
    [Signer, address, isConnected, provider, withSignerIfPossible]
  )
}

export const useProviderOrSigner2 = (withSignerIfPossible = true) => {
  const { address, isConnected } = useAccount()
  const Signer = useEthersSigner()
  return useMemo(
    () => (withSignerIfPossible && address && isConnected && Signer ? Signer : undefined),
    [Signer, address, isConnected, withSignerIfPossible]
  )
}
