import { useQuery } from '@apollo/client'
import { PollingInterval, usePollQueryWhileMounted } from 'graphql/data/util'
import gql from 'graphql-tag'
import { useMemo } from 'react'
import { useHttpLaunchpadListData } from 'state/http/hooks'
import { useActiveChainId } from 'connection/useActiveChainId'
//
//    "discription": {
//                  "token_key": "0xbcaf9c2841193182f1792d2ce370e83b16dddf050fc661c615cca4481b93e2b9",
//                  "description": "my token",
//                  "image_url": "https://image.roguex.io/0x0000000000000000000000000000000000000000.svg",
//                  "twitter": "a33",
//                  "telegram": "gewd",
//                  "website": "dw"
//              }
//
const query = gql`
  query TokenList {
    statuses {
      totalSwapUser
    }
    tokens(first:1000,where: { launchPoolInfo_not: null },orderBy:createdAtTimestamp,orderDirection:desc) {
      userCount
      address
      id
      name
      symbol
      tokenKey
      holder(orderBy: balance, orderDirection: desc) {
        userAddress
        balance
        balance_formatted
        ratio
      }
      memeStaking
      derivedETH
      createdAtTimestamp
      createdOrigin
      baseTokenPriceUSD @client
      metadata @client
      ethPriceUSD @client
      marketCap @client
      derivedUSD @client
      launchPoolInfo {
        pool {
          id
          token0 {
            address
            symbol
          }
          token1 {
            address
            symbol
          }
        }
        isToken0
      }
    }
  }
`

export const useLaunchpadQuery = () => {
  const { data, loading, error } = usePollQueryWhileMounted(useQuery(query), PollingInterval.Fast)
  return useMemo(
    () => ({
      data: data?.tokens,
      ethPriceUSD: data?.ethPriceUSD,
      baseTokenPriceUSD: data?.baseTokenPriceUSD,
      loading,
      error,
    }),
    [data, error, loading]
  )
}

export const useLaunchpadDataByToken = (token?: string) => {
  const { data } = useHttpLaunchpadListData()
  return useMemo(() => {
    if (!data || !token) return
    return data.filter((x: { address: string }) => x.address.toLocaleLowerCase() == token.toLocaleLowerCase())
  }, [data, token])
}

const querySwapHistory = gql`
  query swaps($poolAddress: String!) {
    swaps(first:1000,where: { pool: $poolAddress }, orderBy: timestamp, orderDirection: desc) {
      id
      transaction
      timestamp
      sender
      origin
      recipient
      token0 {
        id
        symbol
      }
      type
      token1 {
        id
        symbol
      }
      amount0
      amount1
    }
  }
`

export const useSwapHistoryQuery = (poolAddress?: string) => {
  const { data, loading, error } = usePollQueryWhileMounted(
    useQuery(querySwapHistory, {
      variables: {
        poolAddress: poolAddress?.toLocaleLowerCase(),
      },
    }),
    PollingInterval.LightningMcQueen
  )

  return useMemo(() => ({ data: data?.swaps, loading, error }), [data, error, loading])
}

const queryUserStaked = gql`
  query accounts($addr: String!) {
  accounts(where:{address:$addr})
    {
      address
      stakingList{
      memeStakingAddress
      amount
      amount_formatted
      tokenInfo{
        id
        symbol
        tokenKey
        metadata @client
      }
      }
    }
  }
`

export const useUserStakedListQuery = () => {
  const { account } = useActiveChainId()
  const { data, loading, error } = usePollQueryWhileMounted(
    useQuery(queryUserStaked, {
      variables: {
        addr: account?.toLocaleLowerCase(),
      },
    }),
    PollingInterval.Lowspeed
  )
  return useMemo(() => ({ data: data?.accounts?.[0]?.stakingList, loading, error }), [data, error, loading])
}

const queryALlRoundMeme = gql`
  query roundStats {
  roundStats
  (first:1000,orderBy:roundId,orderDirection:asc)
  {
    roundId
    RewardStatList{
      roundId
      memeStakingAddress
      memeToken{
        id
        symbol
        tokenKey
        metadata @client
      }
      amount
    }
  }
}
`

export const useALlRoundMeme = () => {
  const { data, loading, error } = usePollQueryWhileMounted(
    useQuery(queryALlRoundMeme),
    PollingInterval.Fast
  )
  return useMemo(() => ({ data: data?.roundStats, loading, error }), [data, error, loading])
}