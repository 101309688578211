import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ReactComponent as FRDIcon } from 'assets/imgs/marketCap/FRD.svg'
import { BaseButton, Pending } from 'components/Button'
import { useActiveChainId } from 'connection/useActiveChainId'
import { useUserStakedListQuery } from 'graphql/thegraph/LaunchpadQuery'
import { useMemeStakingContract } from 'hooks/useContract'
import { useApr, useRewardsMeme } from 'pages/MarketCap/InfoDetail/hooks'
import { useCallback, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useTransactionAdder } from 'state/transactions/hooks'
import { TransactionType } from 'state/transactions/types'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { BN } from 'utils/bn'
import { formatAmount } from 'utils/formatAmout'
import { handlerError } from 'utils/formatError'

import { StyledRewardsList, StyledStakeBtn, StyledThRow, StyledToIcon, StyledTrRow } from './RewardsList'
import StakeDialog from './StakeDialog'
import UnstakeDialog from './UnStakeDialog'

export const StyledClaimBtn = styled(BaseButton)`
  width: fit-content;
  height: 36px;
  min-height: 36px;
  font-size: 14px;
  font-weight: 700;
  color: #ffffff;
  padding: 4px 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(270deg, rgba(255, 204, 0, 0.3) 0%, rgba(20, 182, 0, 0.3) 50%, rgba(0, 126, 73, 0.3) 100%);
  border-radius: 8px;
  &:hover {
    background: linear-gradient(
      270deg,
      rgba(255, 204, 0, 0.6) 0%,
      rgba(20, 182, 0, 0.6) 50%,
      rgba(0, 126, 73, 0.6) 100%
    );
  }
  &:disabled {
    background: ${({ theme }) => theme.primaryBtn_disabled};
    color: ${({ theme }) => theme.textTertiary};
    cursor: not-allowed;
    pointer-events: auto;
    box-shadow: none;
    outline: none;
  }
`

function EarnReward({ memeStaking }: any) {
  const reward = useRewardsMeme(memeStaking)
  return (
    <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
      {formatAmount(reward, 2, true)}
    </ThemedText.TextPrimary>
  )
}

export default function StakedList() {
  const { chainId, account, provider } = useActiveChainId()

  const [attemptingTxn_claim, setAttemptingTxn_claim] = useState<boolean>(false)
  const [txHash, setTxHash] = useState<string>('')
  const [txError, setTxError] = useState<string>('')

  const { data } = useUserStakedListQuery()
  // console.log(data)

  const [stakedAmount, setStakedAmount] = useState(0)
  const [memeStakingAddress, setMemeStakingAddress] = useState('')
  const [tokenImage_url, setTokenImage_url] = useState('')
  const [tokenSymbol, setTokenSymbol] = useState('')
  const [tokenAddress, setTokenAddress] = useState('')

  const [openStake, setOpenStake] = useState(false)
  const handleOpenStakeDialog = () => {
    setOpenStake(true)
  }
  const handleCloseStake = () => {
    setOpenStake(false)
  }

  const [openUnstake, setOpenUnstake] = useState(false)
  const handleOpenUnstakeDialog = () => {
    setOpenUnstake(true)
  }
  const handleCloseUnstake = () => {
    setOpenUnstake(false)
  }

  const addTransaction = useTransactionAdder()

  const StakingContract = useMemeStakingContract(memeStakingAddress)

  const apr = useApr(memeStakingAddress)

  const handlerClaim = useCallback(async () => {
    if (!memeStakingAddress || !StakingContract || !account) return
    setAttemptingTxn_claim(true)
    try {
      const response = await StakingContract.getReward(account)
      setAttemptingTxn_claim(false)
      addTransaction(response, {
        type: TransactionType.CLAIM_REWARDS,
        token0Address: '',
        token1Address: '',
      })
      setTxHash(response.hash)
    } catch (error) {
      setAttemptingTxn_claim(false)
      setTxError(handlerError(error))
      console.log('claim error', error)
    }
  }, [StakingContract, account, addTransaction, memeStakingAddress])

  return (
    <StyledRewardsList>
      <StyledTrRow>
        <Box p="0 8px">
          <ThemedText.TextSecondary fontSize={14} fontWeight={400}>
            <Trans>Token</Trans>
          </ThemedText.TextSecondary>
        </Box>
        <Box display="flex" justifyContent="flex-end" p="0 8px">
          <ThemedText.TextSecondary fontSize={14} fontWeight={400}>
            <Trans>Staked Amount</Trans>
          </ThemedText.TextSecondary>
        </Box>
        <Box display="flex" justifyContent="flex-end" p="0 8px">
          <ThemedText.TextSecondary fontSize={14} fontWeight={400}>
            <Trans>Your Earned</Trans>
          </ThemedText.TextSecondary>
        </Box>
        <Box display="flex" justifyContent="flex-end" p="0 8px"></Box>
      </StyledTrRow>
      {data &&
        data.map((item: any, index: any) => (
          <StyledThRow key={index}>
            <NavLink to={`/infoDetail?token=${item.tokenInfo.id}`} style={{ textDecoration: 'none' }}>
              <Box display="flex" alignItems="center" gap="8px" p="0 8px">
                <img src={item.tokenInfo.metadata.image_url} width="30px" height="30px" />
                <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
                  <Trans>{item.tokenInfo.symbol}</Trans>
                </ThemedText.TextPrimary>
                <StyledToIcon />
              </Box>
            </NavLink>
            <Box display="flex" alignItems="center" gap="8px" justifyContent="flex-end" p="0 8px">
              <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
                {formatAmount(item.amount_formatted, 2, true)}
              </ThemedText.TextPrimary>
            </Box>
            <Box display="flex" alignItems="center" gap="8px" justifyContent="flex-end" p="0 8px">
              <FRDIcon width="30px" height="30px" />
              <EarnReward memeStaking={item.memeStakingAddress} />
            </Box>
            <Box display="flex" alignItems="center" gap="32px" justifyContent="flex-end" p="0 8px">
              <StyledStakeBtn
                onClick={() => {
                  setStakedAmount(item.amount_formatted)
                  setMemeStakingAddress(item.memeStakingAddress)
                  setTokenImage_url(item.tokenInfo.metadata.image_url)
                  setTokenSymbol(item.tokenInfo.symbol)
                  setTokenAddress(item.tokenInfo.id)
                  handleOpenStakeDialog()
                }}
              >
                <ThemedText.TextPrimary fontSize={14} fontWeight={700} className="active-gradient-text">
                  <Trans>STAKE</Trans>
                </ThemedText.TextPrimary>
              </StyledStakeBtn>
              <StyledStakeBtn
                onClick={() => {
                  setStakedAmount(item.amount_formatted)
                  setMemeStakingAddress(item.memeStakingAddress)
                  setTokenImage_url(item.tokenInfo.metadata.image_url)
                  setTokenSymbol(item.tokenInfo.symbol)
                  handleOpenUnstakeDialog()
                }}
              >
                <ThemedText.TextPrimary fontSize={14} fontWeight={700} className="active-gradient-text">
                  <Trans>UNSTAKE</Trans>
                </ThemedText.TextPrimary>
              </StyledStakeBtn>
              <StyledClaimBtn
                onClick={() => {
                  setMemeStakingAddress(item.memeStakingAddress)
                  handlerClaim()
                }}
                disabled={attemptingTxn_claim}
              >
                {attemptingTxn_claim ? <Pending /> : 'CLAIM'}
              </StyledClaimBtn>
            </Box>
          </StyledThRow>
        ))}

      <StakeDialog
        open={openStake}
        handleClose={handleCloseStake}
        stakedAmount={BN(stakedAmount)}
        apr={apr}
        tokenName={tokenSymbol}
        tokenIcon={tokenImage_url}
        tokenAddress={tokenAddress}
        StakingContract={StakingContract}
      />
      <UnstakeDialog
        open={openUnstake}
        handleClose={handleCloseUnstake}
        stakedAmount={BN(stakedAmount)}
        StakingContract={StakingContract}
        tokenName={tokenSymbol}
        tokenIcon={tokenImage_url}
      />
    </StyledRewardsList>
  )
}
