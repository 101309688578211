import { Box } from '@mui/material'
import { ReactComponent as BrowserIcon } from 'assets/imgs/marketCap/browser.svg'
import { ReactComponent as DiscordIcon } from 'assets/imgs/marketCap/discord.svg'
import { ReactComponent as TelegramIcon } from 'assets/imgs/marketCap/telegram.svg'
import { ReactComponent as TwitterIcon } from 'assets/imgs/marketCap/twitter.svg'
import styled from 'styled-components/macro'

const StyledLinkBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  .link-item {
    &:hover {
      cursor: pointer;
      svg {
        path {
          fill: ${({ theme }) => theme.primaryBtn_hover};
        }
      }
    }
  }
`

const StyledLinkBox2 = styled(Box)<{ justify?: string }>`
  width: 100%;
  // display: grid;
  // grid-template-columns: repeat(4, 1fr);
  display: flex;
  justify-content: ${({ justify }) => (justify ? justify : 'flex-start')};
  gap: 6px;
  .link-item {
    width: 72px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    border: 1px solid ${({ theme }) => theme.primaryBorder};
    &:hover {
      cursor: pointer;
      border: 1px solid ${({ theme }) => theme.primaryBtn_hover};
      svg {
        path {
          fill: ${({ theme }) => theme.primaryBtn_hover};
        }
      }
    }
  }
`

const StyledBrowserIcon = styled(BrowserIcon)`
  path {
    fill: ${({ theme }) => theme.textSecondary};
  }
`

const StyledTwitterIcon = styled(TwitterIcon)`
  path {
    fill: ${({ theme }) => theme.textSecondary};
  }
`

const StyledDiscordIcon = styled(DiscordIcon)`
  path {
    fill: ${({ theme }) => theme.textSecondary};
  }
`

const StyledTelegramIcon = styled(TelegramIcon)`
  path {
    fill: ${({ theme }) => theme.textSecondary};
  }
`

export default function LinkBox({ metadata }: any) {
  const handleStop = (e: any, url?: string) => {
    e.stopPropagation()
    e.preventDefault()
    if (url) {
      if (url.includes('https://')) {
        window.open(url, '_blank')
      }
    }
  }
  return (
    <StyledLinkBox>
      {metadata?.website && (
        <a
          onClick={(e) => {
            handleStop(e, metadata?.website)
          }}
          href={metadata?.website}
          target="_blank"
          className="link-item"
          rel="noreferrer"
        >
          <StyledBrowserIcon />
        </a>
      )}
      {metadata?.twitter && (
        <a
          onClick={(e) => {
            handleStop(e, metadata?.twitter)
          }}
          href={metadata?.twitter}
          target="_blank"
          className="link-item"
          rel="noreferrer"
        >
          <StyledTwitterIcon />
        </a>
      )}
      {metadata?.discord && (
        <a
          onClick={(e) => {
            handleStop(e, metadata?.discord)
          }}
          href={metadata?.discord}
          target="_blank"
          className="link-item"
          rel="noreferrer"
        >
          <StyledDiscordIcon />
        </a>
      )}
      {metadata?.telegram && (
        <a
          onClick={(e) => {
            handleStop(e, metadata?.telegram)
          }}
          href={metadata?.telegram}
          target="_blank"
          className="link-item"
          rel="noreferrer"
        >
          <StyledTelegramIcon />
        </a>
      )}
    </StyledLinkBox>
  )
}

export function LinkBox2({ metadata, justify }: any) {
  const handleStop = (e: any, url?: string) => {
    e.stopPropagation()
    e.preventDefault()
    if (url) {
      if (url.includes('https://')) {
        window.open(url, '_blank')
      }
    }
  }

  return (
    <StyledLinkBox2 justify={justify}>
      {metadata?.website && (
        <a
          onClick={(e) => {
            handleStop(e, metadata?.website)
          }}
          href={metadata?.website}
          target="_blank"
          className="link-item"
          rel="noreferrer"
        >
          <StyledBrowserIcon />
        </a>
      )}
      {metadata?.twitter && (
        <a
          onClick={(e) => {
            handleStop(e, metadata?.twitter)
          }}
          href={metadata?.twitter}
          target="_blank"
          className="link-item"
          rel="noreferrer"
        >
          <StyledTwitterIcon />
        </a>
      )}
      {metadata?.discord && (
        <a
          onClick={(e) => {
            handleStop(e, metadata?.discord)
          }}
          href={metadata?.discord}
          target="_blank"
          className="link-item"
          rel="noreferrer"
        >
          <StyledDiscordIcon />
        </a>
      )}
      {metadata?.telegram && (
        <a
          onClick={(e) => {
            handleStop(e, metadata?.telegram)
          }}
          href={metadata?.telegram}
          target="_blank"
          className="link-item"
          rel="noreferrer"
        >
          <StyledTelegramIcon />
        </a>
      )}
    </StyledLinkBox2>
  )
}
