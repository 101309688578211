import { createAction } from '@reduxjs/toolkit'
import { AllPoolsProps } from 'hooks/useQueryAllPools'

export enum Field {
  INPUT = 'INPUT',
  OUTPUT = 'OUTPUT',
}

export enum TRADESATE {
  SWAP,
  LONG,
  SHORT,
}

export const positionSelectCurrency = createAction<{ field: Field; currencyId: string }>('positionSwap/selectCurrency')
export const positionSwitchCurrencies = createAction<void>('positionSwap/switchCurrencies')
export const positionTypeInput = createAction<{ field: Field; typedValue: string }>('positionSwap/typeInput')
// eslint-disable-next-line import/no-unused-modules
export const replacePositionState = createAction<{
  field: Field
  typedValue: string
  inputCurrencyId?: string
  outputCurrencyId?: string
  isLong: TRADESATE
  isToken0: boolean
  sliederLever: number
  poolInfo?: AllPoolsProps
}>('swap/replaceSwapState')
export const setLong = createAction<{ isLong: TRADESATE }>('positionSwap/Long')

export const setSliederLever = createAction<{ sliederLever: number }>('positionSwap/sliederLever')

export const setPoolInfo = createAction<{ poolInfo: AllPoolsProps }>('positionSwap/setPoolInfo')
export const setToken0 = createAction<{ isToken0: boolean }>('trade/token0')
export const setLimit = createAction<{ limit: boolean }>('trade/limit')
