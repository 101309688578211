import { AutoColumn } from 'components/Column'
import styled from 'styled-components/macro'

export const PageWrapper = styled(AutoColumn)`
  padding: 48px 0px 0px;
  max-width: 1280px;
  width: 100%;

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToLarge`
    width: 100%;
  `};

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.xl}px`}) {
    padding: 32px 12px 0px;
  }

  @media only screen and (max-width: ${({ theme }) => `${theme.breakpoint.sm}px`}) {
    padding: 24px 8px 0px;
  }
`
