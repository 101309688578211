import styled from 'styled-components/macro'

export const StyledBorderBox = styled.div`
  background-color: ${({ theme }) => theme.themeBg};
  border-radius: 16px;
  border: 1px solid #00000014;
  // border: 1px solid;
  // border-image-slice: 1;
  // border-image-source: linear-gradient(270deg, rgba(255, 204, 0, 0.3) 0%, rgba(20, 182, 0, 0.3) 50%, rgba(0, 126, 73, 0.3) 100%);
  box-shadow: 5px 4px 0px 0px #00000040;
`
