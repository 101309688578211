import { useMediaQuery } from '@mui/material'
import useDebounce from 'hooks/useDebounce'
import { useAtom } from 'jotai'
import { ChangeEvent, RefObject, useCallback, useRef } from 'react'
import styled from 'styled-components/macro'
import { BREAKPOINTS } from 'theme'

import { searchAtom } from './hooks'

const ICON_SIZE = '16px'
const SearchInput = styled.input<{ width?: string }>`
  background: no-repeat scroll 8px 8px;
  background-image: url(${({ theme }) => theme.searchIcon});
  background-size: 16px 16px;
  background-position: 8px center;
  position: relative;
  display: flex;
  align-items: center;
  padding: 16px;
  padding-left: 32px;
  height: 40px;
  width: ${({ width }) => (width ? width : '100%')};
  max-width: 100%;
  white-space: nowrap;
  border: none;
  outline: none;
  border-radius: 8px;
  font-size: 14px;
  color: ${({ theme }) => theme.textPrimary};
  background-color: ${({ theme }) => theme.backgroundInteractive};
  -webkit-appearance: none;
  transition: border 100ms;

  :focus {
    border: 1px solid ${({ theme }) => theme.activeBorder};
    outline: none;
  }

  :hover {
    border: 1px solid ${({ theme }) => theme.activeBorder};
  }

  ::placeholder {
    color: ${({ theme }) => theme.textTertiary};
  }

  ::-webkit-search-cancel-button {
    -webkit-appearance: none;
    appearance: none;
    height: ${ICON_SIZE};
    width: ${ICON_SIZE};
    background-image: url(${({ theme }) => theme.closeIcon});
    margin-right: 10px;
    background-size: ${ICON_SIZE} ${ICON_SIZE};
    cursor: pointer;
  }
`

export default function Search() {
  const isVerySmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.sm}px)`)
  const [searchQuery, setSearchQuery] = useAtom(searchAtom)
  const debouncedQuery = useDebounce(searchQuery, 200)
  const inputRef = useRef<HTMLInputElement>()
  const handleInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const input = event.target.value
      setSearchQuery(input)
    },
    [setSearchQuery]
  )

  const handleEnter = useCallback(
    (e: any) => {
      if (e.key === 'Enter') {
        const s = debouncedQuery.toLowerCase().trim()
      }
    },
    [debouncedQuery]
  )

  return (
    <>
      <SearchInput
        type="text"
        width={isVerySmallScreen ? '100%' : '600px'}
        id="token-search-input"
        data-testid="token-search-input"
        placeholder="Search"
        autoComplete="off"
        value={searchQuery}
        ref={inputRef as RefObject<HTMLInputElement>}
        onChange={handleInput}
        onKeyDown={handleEnter}
      />
    </>
  )
}
function useAuto(searchAtom: any): [any, any] {
  throw new Error('Function not implemented.')
}
