import { useActiveChainId } from 'connection/useActiveChainId'
import { useToken } from 'hooks/Tokens'
import { atomWithReset, useAtomValue } from 'jotai/utils'
import { useTokenBalance } from 'lib/hooks/useCurrencyBalance'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { useMemo } from 'react'
import { useHttpLaunchpadListData } from 'state/http/hooks'
import { BN, fromSqrt96Wei } from 'utils/bn'

import { usePoolSqrtRatioX96 } from './InfoDetail/hooks'
import { MarketSortMethod, sortAscendingAtom, sortMethodAtom, sortPageAtom } from './state'

export const searchAtom = atomWithReset('')

const pageSize = 12

export const useDataList = () => {
  const { data, loading } = useHttpLaunchpadListData()

  const sortMethod = useAtomValue(sortMethodAtom)
  const sortAscending = useAtomValue(sortAscendingAtom)
  const sortPage = useAtomValue(sortPageAtom)

  const sortData = useMemo(() => {
    if (!data) return
    let marketCapArray = [...data]

    switch (sortMethod) {
      case MarketSortMethod.Marketcap:
        marketCapArray = marketCapArray.sort((a: any, b: any) => Number(b.marketCap || 0) - Number(a.marketCap || 0))
        break

      case MarketSortMethod.Participants:
        marketCapArray = marketCapArray.sort((a: any, b: any) => Number(b.userCount || 0) - Number(a.userCount || 0))
        break

      case MarketSortMethod.CreatedIn:
        marketCapArray = marketCapArray.sort(
          (a: any, b: any) => Number(b.createdAtTimestamp || 0) - Number(a.createdAtTimestamp || 0)
        )
        break
    }
    return sortAscending ? marketCapArray.reverse() : marketCapArray
  }, [data, sortAscending, sortMethod])

  const filteredMarketCap = useFilteredMarketCap(sortData)?.slice(
    (sortPage - 1) * pageSize,
    (sortPage - 1) * pageSize + pageSize
  )

  return filteredMarketCap
}

const useFilteredMarketCap = (data: any) => {
  const searchStr = useAtomValue(searchAtom)
  const lowercaseFilterString = useMemo(() => searchStr.toLowerCase(), [searchStr])

  return useMemo(() => {
    if (!data) return undefined
    let returnDatas = data
    if (lowercaseFilterString) {
      returnDatas = returnDatas?.filter((token: any) => {
        const tokenAddress = token.address
        const nameStr = token.name
        const symbolStr = token.symbol
        const nameIncludesFilterString = nameStr.toLowerCase().includes(lowercaseFilterString)
        const symbolIncludesFilterString = symbolStr.toLowerCase().includes(lowercaseFilterString)
        const tokenAddressIncludesFilterString = tokenAddress.toLowerCase().includes(lowercaseFilterString)
        return nameIncludesFilterString || symbolIncludesFilterString || tokenAddressIncludesFilterString
      })
    }
    return returnDatas
  }, [data, lowercaseFilterString])
}

export function useMarketCapLength() {
  const { data } = useHttpLaunchpadListData()
  return useMemo(() => {
    if (!data) return 0
    return Math.ceil(data.length / pageSize)
  }, [data])
}

export const usePoolLiquidity = (poolAddress: string, tokenAddress: string) => {
  const { chainId } = useActiveChainId()
  const token = useToken(tokenAddress)
  const tokenBalance = useTokenBalance(poolAddress, token ?? undefined)
  const nativeCurrency = useNativeCurrency(chainId)
  const wethBalance = useTokenBalance(poolAddress, nativeCurrency.wrapped ?? undefined)

  return useMemo(() => {
    if (!tokenBalance || !wethBalance)
      return {
        tokenBalance: undefined,
        wethBalance: undefined,
      }
    return {
      tokenBalance,
      wethBalance,
    }
  }, [tokenBalance, wethBalance])
}

export const useTokenMarketCap = (poolAddress: string, isToken0: boolean) => {
  const tokenSupply = BN(1000000000)
  const poolSqrtRatioX96 = usePoolSqrtRatioX96(poolAddress)
  return useMemo(() => {
    if (!poolSqrtRatioX96) return
    const price = fromSqrt96Wei(poolSqrtRatioX96.toString())
    if (isToken0) {
      return tokenSupply.multipliedBy(price)
    } else {
      return tokenSupply.multipliedBy(BN(1).div(price))
    }
  }, [poolSqrtRatioX96, isToken0, tokenSupply])
}
