import { ArrowChangeDown } from 'components/Icons/ArrowChangeDown'
import { ArrowChangeUp } from 'components/Icons/ArrowChangeUp'
import { useAtomValue } from 'jotai/utils'
import styled, { css, useTheme } from 'styled-components/macro'
import { ThemedText } from 'theme'

import { MarketSortMethod, sortAscendingAtom, sortMethodAtom, useSetSortMethod } from './state'

const ClickableStyle = css`
  text-decoration: none;
  cursor: pointer;
  transition-duration: ${({ theme }) => theme.transition.duration.fast};

  :hover {
    opacity: ${({ theme }) => theme.opacity.hover};
  }
  :active {
    opacity: ${({ theme }) => theme.opacity.click};
  }
`

const HeaderCellWrapper = styled.span<{ onClick?: () => void; justify?: string }>`
  align-items: center;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'unset')};
  display: flex;
  gap: 4px;
  justify-content: ${({ justify }) => (justify ? justify : 'flex-end')};
  width: 100%;

  &:hover {
    ${ClickableStyle}
  }
`

export default function HeaderCell({
  category,
  justify,
}: {
  category: MarketSortMethod // TODO: change this to make it work for trans
  justify?: string
}) {
  const theme = useTheme()
  const sortAscending = useAtomValue(sortAscendingAtom)
  const handleSortCategory = useSetSortMethod(category)
  const sortMethod = useAtomValue(sortMethodAtom)

  return (
    <HeaderCellWrapper onClick={handleSortCategory} justify={justify}>
      {sortMethod === category && (
        <>
          {sortAscending ? (
            <ArrowChangeUp width={16} height={16} color={theme.textPrimary} />
          ) : (
            <ArrowChangeDown width={16} height={16} color={theme.textSecondary} />
          )}
        </>
      )}

      <ThemedText.TextSecondary fontSize={12} fontWeight={700} style={{ whiteSpace: 'nowrap' }}>
        {category}
      </ThemedText.TextSecondary>
    </HeaderCellWrapper>
  )
}
