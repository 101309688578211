import { Trans } from '@lingui/macro'
import { Box, useMediaQuery } from '@mui/material'
import { PageWrapper } from 'components/PageWrapper'
import { useLaunchpadDataByToken } from 'graphql/thegraph/LaunchpadQuery'
import { useLocation } from 'react-router-dom'
import { BREAKPOINTS, ThemedText } from 'theme'

import ChartSection from './ChartSection'
import HolderList from './HolderList'
import Info from './Info'
import Swap from './Swap'
import TradeList from './TradeList'
import TradeListM from './TradeList-m'

export default function InfoDetail() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xl}px)`)

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const token = queryParams.get('token')

  const data = useLaunchpadDataByToken(token ?? undefined)
  return (
    <PageWrapper className="info-detail">
      {data && data?.length > 0 && (
        <Box
          display="flex"
          flexDirection={isSmallScreen ? 'column' : 'row'}
          alignItems="flex-start"
          width="100%"
          gap="24px"
        >
          <Box className="flex-1" width={isSmallScreen ? '100%' : 'auto'}>
            <Info infoData={data[0]} />
            <Box mt="16px">
              <ChartSection lpAddress={data[0].launchPoolInfo.pool.id} />
            </Box>

            {!isSmallScreen && (
              <Box m="16px 0">
                <ThemedText.TextSecondary fontSize={16} fontWeight={700} mb="16px">
                  <Trans>Trade</Trans>
                </ThemedText.TextSecondary>
                <TradeList infoData={data[0]} />
              </Box>
            )}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            gap="16px"
            width={isSmallScreen ? '100%' : '340px'}
          >
            <Swap infoData={data[0]} />
            <HolderList infoData={data[0]} />
            {isSmallScreen && (
              <Box flexDirection="column" gap="16px" width="100%">
                <ThemedText.TextSecondary fontSize={16} fontWeight={700} mb="16px">
                  <Trans>Trade</Trans>
                </ThemedText.TextSecondary>
                <TradeListM infoData={data[0]} />
              </Box>
            )}
          </Box>
        </Box>
      )}
    </PageWrapper>
  )
}
