import { useConnectModal } from '@rainbow-me/rainbowkit'
import PortfolioDrawer from 'components/AccountDrawer'
import PrefetchBalancesWrapper from 'components/AccountDrawer/PrefetchBalancesWrapper'
import { Portal } from 'nft/components/common/Portal'

// function Web3StatusInner() {
//   const switchingChain = useAppSelector((state) => state.wallets.switchingChain)
//   const ignoreWhileSwitchingChain = useCallback(() => !switchingChain, [switchingChain])
//   const { connector, ENSName } = useLast(useWeb3React(), ignoreWhileSwitchingChain)
//   const connection = getConnection(connector)
//   const { account } = useActiveChainId()

//   const [, toggleAccountDrawer] = useAccountDrawer()
//  const { connectModalOpen: accountDrawerOpen, openConnectModal } = useConnectModal()
// const toggleAccountDrawer = () => openConnectModal && openConnectModal()
//   const handleWalletDropdownClick = useCallback(() => {
//     sendAnalyticsEvent(InterfaceEventName.ACCOUNT_DROPDOWN_BUTTON_CLICKED)
//     toggleAccountDrawer()
//   }, [toggleAccountDrawer])
//   const isClaimAvailable = useIsNftClaimAvailable((state) => state.isClaimAvailable)

//   const allTransactions = useAllTransactions()

//   const sortedRecentTransactions = useMemo(() => {
//     const txs = Object.values(allTransactions)
//     return txs.filter(isTransactionRecent).sort(newTransactionsFirst)
//   }, [allTransactions])

//   const pendingOrders = usePendingOrders()

//   const pendingTxs = sortedRecentTransactions.filter((tx) => !tx.receipt).map((tx) => tx.hash)

//   const hasPendingActivity = !!pendingTxs.length || !!pendingOrders.length

//   if (account) {
//     return (
//       <TraceEvent
//         events={[BrowserEvent.onClick]}
//         name={InterfaceEventName.MINI_PORTFOLIO_TOGGLED}
//         properties={{ type: 'open' }}
//       >
//         <Web3StatusConnected
//           disabled={Boolean(switchingChain)}
//           data-testid="web3-status-connected"
//           onClick={handleWalletDropdownClick}
//           pending={hasPendingActivity}
//           isClaimAvailable={isClaimAvailable}
//         >
//           {!hasPendingActivity && (
//             <StatusIcon account={account} size={24} connection={connection} showMiniIcons={false} />
//           )}
//           {hasPendingActivity ? (
//             <RowBetween>
//               <Text>
//                 <Trans>{pendingTxs.length + pendingOrders.length} Pending</Trans>
//               </Text>{' '}
//               <Loader stroke="white" />
//             </RowBetween>
//           ) : (
//             <AddressAndChevronContainer>
//               <Text>{ENSName || shortenAddress(account)}</Text>
//             </AddressAndChevronContainer>
//           )}
//         </Web3StatusConnected>
//       </TraceEvent>
//     )
//   } else {
//     return (
//       <TraceEvent
//         events={[BrowserEvent.onClick]}
//         name={InterfaceEventName.CONNECT_WALLET_BUTTON_CLICKED}
//         element={InterfaceElementName.CONNECT_WALLET_BUTTON}
//       >
//         <ButtonSecondary height="40px"
//           tabIndex={0}
//           onKeyPress={(e) => e.key === 'Enter' && handleWalletDropdownClick()}
//           onClick={handleWalletDropdownClick}
//         >
//           <StyledConnectButton tabIndex={-1} data-testid="navbar-connect-wallet">
//             <Trans>Connect</Trans>
//           </StyledConnectButton>
//         </ButtonSecondary>
//       </TraceEvent>
//     )
//   }
// }

export default function Web3Status() {
  // const [isDrawerOpen] = useAccountDrawer()
  const { connectModalOpen: isDrawerOpen, openConnectModal } = useConnectModal()
  const toggleWalletDrawer = () => openConnectModal && openConnectModal()
  return (
    <PrefetchBalancesWrapper shouldFetchOnAccountUpdate={isDrawerOpen}>
      {/* <Web3StatusInner /> */}
      <Portal>
        <PortfolioDrawer />
      </Portal>
    </PrefetchBalancesWrapper>
  )
}
