import { Box, useMediaQuery } from '@mui/material'
import GoldIcon from 'assets/imgs/marketCap/gold.svg'
import GoldMIcon from 'assets/imgs/marketCap/gold-m.svg'
import { ReactComponent as InfoActiveIcon } from 'assets/imgs/marketCap/info-active.svg'
import { ReactComponent as MarketActiveIcon } from 'assets/imgs/marketCap/market-active.svg'
import { StyledInfoIcon, StyledMarketIcon } from 'components/Icons/StyledIcon'
import { PageWrapper } from 'components/PageWrapper'
import Row from 'components/Row'
import { useMemo, useState } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'
import { BREAKPOINTS } from 'theme'

import InfoList from './InfoList'
import MarketList from './MarketList'
import MarketSort from './MarketSort'
import Search from './Search'
import { StyledBorderBox } from './StyledBorderBox'

const StyledTab = styled(StyledBorderBox)`
  width: fit-content;
  display: flex;
  gap: 8px;
  padding: 8px 10px;
`

export default function MarketCap() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.xl}px)`)
  const isSmallScreen2 = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)
  const isVerySmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.sm}px)`)

  const [curr, setCurr] = useState(0)

  const tabList = useMemo(() => {
    return isVerySmallScreen
      ? [{ icon: <StyledMarketIcon />, activeIcon: <MarketActiveIcon /> }]
      : [
          { icon: <StyledMarketIcon />, activeIcon: <MarketActiveIcon /> },
          { icon: <StyledInfoIcon />, activeIcon: <InfoActiveIcon /> },
        ]
  }, [isVerySmallScreen])

  return (
    <PageWrapper className="market-cap">
      {/* <Box display="flex" justifyContent="center" width="100%">
        <NavLink to="/mega">
          <img src={isSmallScreen2 ? GoldMIcon : GoldIcon} />
        </NavLink>
      </Box> */}
      <Box
        display="flex"
        flexDirection={isSmallScreen ? 'column' : 'row'}
        justifyContent={isSmallScreen ? 'flex-start' : 'space-between'}
        alignItems={isSmallScreen ? 'flex-start' : 'center'}
        gap="16px"
        mt="40px"
      >
        {curr == 0 && <MarketSort />}
        <Row justify={isSmallScreen ? 'flex-start' : 'flex-end'} gap="16px">
          <Search />
          <StyledTab>
            {tabList.map(({ icon, activeIcon }, i) => (
              <Box
                key={i}
                className={`tabItem ${curr == i && 'tabItemActive'}`}
                onClick={() => setCurr(i)}
                style={{
                  cursor: 'pointer',
                }}
              >
                {curr == i ? activeIcon : icon}
              </Box>
            ))}
          </StyledTab>
        </Row>
      </Box>
      <Box mt={curr == 0 ? '72px' : '32px'}>{curr == 0 ? <MarketList /> : <InfoList />}</Box>

      {/* <Box mt={curr == 0 ? '72px' : '32px'}>
        <InfoList />
      </Box> */}
    </PageWrapper>
  )
}
