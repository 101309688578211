import ORDERBOOKABI from 'abis/PerpOrderbook.json'
import { getContract2 } from 'utils/getContract'

import { ORDER_BOOK_ADDRESSES } from './addresses'

export const getOrderBookContract = (chainId: any, siger?: any) => {
  const addr = chainId ? ORDER_BOOK_ADDRESSES[chainId] : ''

  return getContract2(addr, ORDERBOOKABI.abi, siger) as any
}
