import Row from 'components/Row'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme/components/text'

const TabItme = styled.div`
  width: 100%;
  padding: 6px;
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.swapSectionBorder};
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    background: ${({ theme }) => theme.backgroundHover};
  }
`

const data = [
  {
    name: '25%',
    value: 0.25,
  },
  {
    name: '50%',
    value: 0.5,
  },
  {
    name: '75%',
    value: 0.75,
  },
  {
    name: '100%',
    value: 1,
  },
]
type TabItmeProps = {
  onChange?: (value: number | string) => void
  dataList?: {
    name: string
    value: number | string
  }[]
}

export default function MaxTab({ dataList, onChange }: TabItmeProps) {
  const MapList = dataList ?? data
  const change = (value: number | string) => {
    onChange && onChange(value)
  }
  return (
    <Row gap="8px">
      {MapList?.map((item, index) => {
        return (
          <TabItme key={'MaxTab' + index} onClick={() => change(item.value)}>
            <ThemedText.TextSecondary fontWeight="700" fontSize="14px">
              {item.name}
            </ThemedText.TextSecondary>
          </TabItme>
        )
      })}
    </Row>
  )
}
