import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import { setVoteCurrentId } from './actions'

export function useHttpPoolListData() {
  return useAppSelector((state) => state.http.poolList)
}
export function useHttpLaunchpadListData() {
  return useAppSelector((state) => state.http.launchpadList)
}

export function useHttpEthPrice() {
  return useAppSelector((state) => state.http.ethPrice)
}
export function useHttpRoxPrice() {
  return useAppSelector((state) => state.http.roxPrice)
}

export function useVoteCurrentId() {
  return useAppSelector((state) => state.http.VoteCurrentId)
}

export function useAcitonsetCurrentId() {
  const dispatch = useAppDispatch()

  const setCurrentId = useCallback(
    (id: number) => {
      dispatch(setVoteCurrentId({ id }))
    },
    [dispatch]
  )

  return { setCurrentId }
}
