import { useActiveChainId } from 'connection/useActiveChainId'
import { UniswapXOrderStatus } from 'lib/hooks/orders/types'
import { useMemo } from 'react'
import { useAppSelector } from 'state/hooks'

import { SignatureDetails, SignatureType, UniswapXOrderDetails } from './types'

export function useAllSignatures(): { [id: string]: SignatureDetails } {
  const { account } = useActiveChainId()
  const signatures = useAppSelector((state) => state.signatures) ?? {}
  if (!account || !signatures[account]) return {}
  return signatures[account]
}

// eslint-disable-next-line import/no-unused-modules
export function usePendingOrders(): UniswapXOrderDetails[] {
  const signatures = useAllSignatures()
  return useMemo(() => {
    return Object.values(signatures).filter(isPendingOrder)
  }, [signatures])
}

export function useOrder(orderHash: string): UniswapXOrderDetails | undefined {
  const signatures = useAllSignatures()
  return useMemo(() => {
    const order = signatures[orderHash]
    if (!order || order.type !== SignatureType.SIGN_UNISWAPX_ORDER) return undefined
    return order
  }, [signatures, orderHash])
}

export function isFinalizedOrder(orderStatus: UniswapXOrderStatus) {
  return orderStatus !== UniswapXOrderStatus.OPEN
}

export function isOnChainOrder(orderStatus: UniswapXOrderStatus) {
  return orderStatus === UniswapXOrderStatus.FILLED || orderStatus === UniswapXOrderStatus.CANCELLED
}

function isPendingOrder(signature: SignatureDetails): signature is UniswapXOrderDetails {
  return signature.type === SignatureType.SIGN_UNISWAPX_ORDER && signature.status === UniswapXOrderStatus.OPEN
}
