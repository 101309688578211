import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ReactComponent as BondingIcon } from 'assets/imgs/marketCap/bonding.svg'
import { ReactComponent as DevIcon } from 'assets/imgs/marketCap/dev.svg'
import HolderBtn from 'assets/imgs/marketCap/holderBtn.svg'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { shortenAddress } from 'utils'
import { BN } from 'utils/bn'

import { StyledBorderBox } from '../StyledBorderBox'

const StyledHolderBox = styled(StyledBorderBox)`
  padding: 16px;
`

const StyledHolderBtn = styled(Box)`
  margin-left: -26px;
  width: 211px;
  height: 37px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-image: url(${HolderBtn});
  background-size: 100% 100%;
  background-repeat: no-repeat;
`

const StyledHolderList = styled(Box)`
  margin-top: 8px;
  .holder-item {
    padding: 8px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed ${({ theme }) => theme.primaryBorder};
  }
`

export default function HolderList({ infoData }: any) {
  const holderData = infoData.holder

  const isDev = (userAddress: string) => {
    return userAddress == infoData.createdOrigin
  }

  const isBondingCurve = (userAddress: string) => {
    return userAddress == infoData.launchPoolInfo.pool.id
  }

  return (
    <Box display="flex" flexDirection="column" width="100%">
      <StyledHolderBox>
        <StyledHolderBtn>
          <ThemedText.TextPrimary color="white" fontSize={16} fontWeight={700} ml="16px">
            <Trans>Holders</Trans>
          </ThemedText.TextPrimary>
        </StyledHolderBtn>
        <StyledHolderList>
          {holderData &&
            holderData.map((item: any, i: any) => (
              <div className="holder-item" key={i}>
                <Box display="flex" alignItems="center" gap="4px">
                  <ThemedText.TextSecondary fontSize={14} fontWeight={400}>
                    <Trans>{Number(i) + 1}.</Trans>
                  </ThemedText.TextSecondary>
                  <ThemedText.TextSecondary fontSize={12} fontWeight={400}>
                    <Trans>{shortenAddress(item.userAddress)}</Trans>
                  </ThemedText.TextSecondary>
                  {isDev(item.userAddress) && (
                    <Box display="flex" alignItems="center" gap="4px">
                      <DevIcon />
                      <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                        <Trans>(Dev)</Trans>
                      </ThemedText.TextSecondary>
                    </Box>
                  )}
                  {isBondingCurve(item.userAddress) && (
                    <Box display="flex" alignItems="center" gap="4px">
                      <BondingIcon />
                      <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
                        <Trans>(LP)</Trans>
                      </ThemedText.TextSecondary>
                    </Box>
                  )}
                </Box>
                <ThemedText.TextSecondary fontSize={14} fontWeight={400}>
                  <Trans>{BN(item.ratio).multipliedBy(100).toFixed(4)}%</Trans>
                </ThemedText.TextSecondary>
              </div>
            ))}
        </StyledHolderList>
      </StyledHolderBox>
    </Box>
  )
}
