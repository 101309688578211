import 'rc-slider/assets/index.css'
import 'assets/css/animista.css'
import 'assets/css/rainbowkit.css'
import 'react-toastify/dist/ReactToastify.css'

import { useMediaQuery } from '@mui/material'
import { Trace } from 'analytics'
import Loader from 'components/Icons/LoadingSpinner'
import Releases from 'components/Releases'
import { useFeatureFlagsIsLoaded } from 'featureFlags'
import { useBag } from 'nft/hooks/useBag'
import { Suspense, useEffect, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { cssTransition, ToastContainer } from 'react-toastify'
import styled from 'styled-components/macro'
import { BREAKPOINTS } from 'theme'
import { flexRowNoWrap } from 'theme/styles'
import { Z_INDEX } from 'theme/zIndex'
import { getCurrentPageFromLocation } from 'utils/urlRoutes'

import ErrorBoundary from '../components/ErrorBoundary'
import NavBar from '../components/NavBar'
import Polling from '../components/Polling'
import Popups from '../components/Popups'
import DarkModeQueryParamReader from '../theme/components/DarkModeQueryParamReader'
import How from './How'
import MarketCap from './MarketCap'
import Create from './MarketCap/Create'
import InfoDetail from './MarketCap/InfoDetail'
import Mega from './Mega'
import NotFound from './NotFound'
import Stake from './Stake'

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  padding: ${({ theme }) => theme.navHeight}px 0px 5rem 0px;
  align-items: center;
  flex: 1;
`

const HeaderWrapper = styled.div<{ transparent?: boolean }>`
  ${flexRowNoWrap};
  background-color: ${({ theme, transparent }) => !transparent && theme.backgroundModule};
  border-bottom: ${({ theme, transparent }) => !transparent && `1px solid ${theme.backgroundOutline}`};
  width: 100%;
  justify-content: space-between;
  position: fixed;
  top: 0;
  z-index: ${Z_INDEX.dropdown};
`

export default function App() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.md}px)`)
  const isLoaded = useFeatureFlagsIsLoaded()

  const { pathname } = useLocation()
  const currentPage = getCurrentPageFromLocation(pathname)
  const [scrolledState, setScrolledState] = useState(false)
  const Zoom = cssTransition({
    enter: 'zoomIn',
    exit: 'zoomOut',
    appendPosition: false,
    collapse: true,
    collapseDuration: 200,
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    setScrolledState(false)
  }, [pathname])

  useEffect(() => {
    const scrollListener = () => {
      setScrolledState(window.scrollY > 0)
    }
    window.addEventListener('scroll', scrollListener)
    return () => window.removeEventListener('scroll', scrollListener)
  }, [])

  const isBagExpanded = useBag((state) => state.bagExpanded)
  const isHeaderTransparent = !scrolledState && !isBagExpanded

  return (
    <ErrorBoundary>
      <ToastContainer
        // limit={1}
        draggable={false}
        // transition={Zoom}
        position="bottom-right"
        // closeButton={false}
        newestOnTop={false}
        pauseOnFocusLoss
        closeOnClick={false}
        // icon={false}
      />
      <DarkModeQueryParamReader />
      <Trace page={currentPage}>
        <HeaderWrapper transparent={isHeaderTransparent}>
          <NavBar blur={isHeaderTransparent} />
        </HeaderWrapper>
        <BodyWrapper>
          <Popups />
          <Polling />
          {!isSmallScreen && <Releases />}
          <Suspense fallback={<Loader />}>
            {isLoaded ? (
              <Routes>
                <Route path="/" element={<MarketCap />} />
                <Route path="/infoDetail" element={<InfoDetail />} />
                <Route path="/create" element={<Create />} />
                <Route path="/how" element={<How />} />
                {/* <Route path="/mega" element={<Mega />} /> */}
                <Route path="/stake" element={<Stake />} />
                <Route path="*" element={<Navigate to="/not-found" replace />} />
                <Route path="/not-found" element={<NotFound />} />
              </Routes>
            ) : (
              <Loader />
            )}
          </Suspense>
        </BodyWrapper>
      </Trace>
    </ErrorBoundary>
  )
}
