import { useActiveChainId } from 'connection/useActiveChainId'
import { BaseToken_ADDRESSES } from 'constants/addresses'
import { useCurrency } from 'hooks/Tokens'
import { useBaseStakingContract, useTokenContract } from 'hooks/useContract'
import { useSingleCallResult } from 'lib/hooks/multicall'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { useMemo } from 'react'
import { useHttpEthPrice } from 'state/http/hooks'
import { BN, fromWei } from 'utils/bn'

export const useStakedToken = () => {
  const { account, chainId } = useActiveChainId()

  const StakingContract = useBaseStakingContract()

  const tokenCurrency = useCurrency(BaseToken_ADDRESSES[chainId])

  const { result } = useSingleCallResult(StakingContract, 'balanceOf', [account])
  return useMemo(() => {
    if (!result || !tokenCurrency) return
    return fromWei(result[0].toString())
  }, [result, tokenCurrency])
}

export const useRewards = () => {
  const StakingContract = useBaseStakingContract()

  const { account, chainId } = useActiveChainId()

  const nativeCurrency = useNativeCurrency(chainId)

  const { result } = useSingleCallResult(StakingContract, 'earned', [nativeCurrency.wrapped.address, account])

  return useMemo(() => {
    if (!result) return
    return fromWei(result[0].toString())
  }, [result])
}

export const useApr = () => {
  const StakingContract = useBaseStakingContract()
  const ethPrice = useHttpEthPrice()
  const { chainId } = useActiveChainId()

  const nativeCurrency = useNativeCurrency(chainId)

  const { result: rewardsResult } = useSingleCallResult(StakingContract, 'rewardRate', [nativeCurrency.wrapped.address])

  const { result: totalStakedResult } = useSingleCallResult(StakingContract, 'totalSupply')

  return useMemo(() => {
    if (!rewardsResult || !totalStakedResult || !ethPrice) return
    // console.log(
    //   'rewards',
    //   fromWei(rewardsResult[0].toString()).toString(),
    //   fromWei(totalStakedResult[0].toString()).toString(),
    //   ethPrice
    // )
    if (rewardsResult[0].toString() == 0) return
    if (totalStakedResult[0].toString() == 0) return
    return fromWei(rewardsResult[0].toString())
      .times(BN(365))
      .times(BN(86400))
      .times(BN(ethPrice))
      .div(fromWei(totalStakedResult[0].toString()).times(BN(ethPrice)))
      .times(BN(100))
  }, [rewardsResult, ethPrice, totalStakedResult])
}

export const useTotalSupply = () => {
  const StakingContract = useBaseStakingContract()
  const { chainId } = useActiveChainId()

  const { result } = useSingleCallResult(StakingContract, 'totalSupply')

  return useMemo(() => {
    if (!result) return
    return fromWei(result[0].toString())
  }, [result])
}

export const useBaseTokenSupply = () => {
  const { chainId } = useActiveChainId()
  const BaseTokenContract = useTokenContract(BaseToken_ADDRESSES[chainId])

  const { result } = useSingleCallResult(BaseTokenContract, 'totalSupply')

  return useMemo(() => {
    if (!result) return
    return fromWei(result[0].toString())
  }, [result])
}
