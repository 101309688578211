import { Box } from '@mui/material'
import styled from 'styled-components/macro'

const StyledChartBox = styled(Box)`
  width: 100%;
  height: 440px;
  border-radius: 16px;
  background: ${({ theme }) => theme.themeBg};
`

export default function ChartSection({ lpAddress }: any) {
  return (
    <StyledChartBox>
      <iframe
        height="100%"
        width="100%"
        style={{ border: 'none' }}
        id="dexscreener-embed"
        title="dexscreener Embed"
        src={`https://dexscreener.com/blast/${lpAddress}?embed=1&theme=dark&trades=0&info=0`}
        allow="clipboard-write"
      ></iframe>
    </StyledChartBox>
  )
}
