import { Box, useMediaQuery } from '@mui/material'
import { useALlRoundMeme } from 'graphql/thegraph/LaunchpadQuery'
import { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import EpochDropdown from './EpochDropdown'
import RewardsList from './RewardsList'
import StakedList from './StakedList'
import { BREAKPOINTS } from 'theme'
import RewardsListM from './RewardsList-m'
import StakedListM from './StakedList-m'

const StyledTab = styled(Box)`
  display: flex;
  gap: 36px;
  .tab {
    font-size: 20px;
    font-weight: 700;
    color: ${({ theme }) => theme.textSecondary};
    padding-bottom: 4px;
    cursor: pointer;
  }
  .tab-active {
    border-bottom: 2px solid;
    border-image: linear-gradient(270deg, rgba(255, 204, 0, 0.3) 0%, rgba(20, 182, 0, 0.3) 50%, rgba(0, 126, 73, 0.3) 100%);
    border-image-slice: 2;
  }
`

export default function StakeInfo() {
  const isSmallScreen = useMediaQuery(`(max-width: ${BREAKPOINTS.lg}px)`)
  const [curr, setCurr] = useState(0)
  const { data } = useALlRoundMeme()
  const [epochNum, setEpochNum] = useState<any>()
  const [epochList, setEpochList] = useState()
  useEffect(() => {
    if (data && data.length > 0) {
      if (epochNum == undefined) {
        setEpochNum(Number(data?.length)-1)
      } else {
        setEpochList(data[epochNum].RewardStatList)
      }
    }
  }, [epochNum, data])
  
  return (
    <>
      <StyledTab>
        <Box
          display="flex"
          alignItems="center"
          gap="8px"
          className={`tab ${curr == 0 && 'tab-active'}`}
          onClick={() => setCurr(0)}
        >
          <div className={` ${curr == 0 && 'active-gradient-text'}`}>Rewards</div>
          <EpochDropdown epochNum={epochNum} setEpochNum={setEpochNum} epochLength={data ? data.length : 0} />
        </Box>
        <div className={`tab ${curr == 1 && 'tab-active active-gradient-text'}`} onClick={() => setCurr(1)}>
          Staked MEME
        </div>
      </StyledTab>
      <Box mt="16px">
        {curr == 0 ? isSmallScreen ? <RewardsListM epochList={epochList} /> :<RewardsList epochList={epochList} />: isSmallScreen ? <StakedListM />:<StakedList />}
      </Box>
    </>
  )
}
