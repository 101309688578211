import { Trans } from '@lingui/macro'
import { Box } from '@mui/material'
import { ReactComponent as ParticipantsIcon } from 'assets/imgs/marketCap/participants.svg'
import Column from 'components/Column'
import { useAtom } from 'jotai'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { shortenAddress } from 'utils'
import { computeNumUnit } from 'utils/formatNum'

import { formatAmount } from '../../utils/formatAmout'
import HeaderCell from './HeaderCell'
import { useDataList, useMarketCapLength, usePoolLiquidity } from './hooks'
import LinkBox from './LinkBox'
import { LoadingRows } from './MarketList'
import { MarketSortMethod, sortPageAtom } from './state'
import { StyledBorderBox } from './StyledBorderBox'
import { StyledPagination } from './StyledPagination'

const StyledInfoList = styled(Box)`
  .desc {
    word-break: break-word;
  }
`

const StyledInfoRow = styled(StyledBorderBox)`
  width: 100%;
  display: grid;
  grid-template-columns: 1.2fr 140px 140px 160px 160px 1fr;
`
const StyledTrRow = styled(StyledInfoRow)`
  padding: 12px;
`
const StyledThRow = styled(StyledInfoRow)`
  padding: 20px 12px;
  .desc {
    -webkit-line-clamp: 3;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    border: 1px solid rgba(0, 126, 73, 0.3);
    cursor: pointer;
  }
`

export const StyledParticipantsIcon = styled(ParticipantsIcon)`
  path {
    fill: ${({ theme }) => theme.textTertiary};
  }
`

export function handleCreatedTime(createdAtTimestamp: any) {
  const createdTime = Math.floor(Number(new Date()) / 1000) - Number(createdAtTimestamp)
  const d: any = Math.floor(createdTime / 60 / 60 / 24)
  // d = d < 10 ? '0' + d : d
  const h: any = Math.floor((createdTime / 60 / 60) % 24)
  // h = h < 10 ? '0' + h : h
  const m: any = Math.floor((createdTime / 60) % 60)
  // m = m < 10 ? '0' + m : m
  const s: any = Math.floor(createdTime % 60)
  return d >= 1 ? `${d}d ${h}h ${m}m ago` : h >= 1 ? `${h}h ${m}m ago` : m >= 1 ? `${m}m ago` : `${s}s ago`
}

export default function InfoList() {
  const data = useDataList()

  const count = useMarketCapLength()

  const [marketCapSortPage, setMarketCapSortPage] = useAtom(sortPageAtom)
  const handleNextPage = (e: any, p: any) => {
    setMarketCapSortPage(p)
  }

  return (
    <StyledInfoList>
      <StyledTrRow>
        <Box p="0 12px">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{MarketSortMethod.INFO}</Trans>
          </ThemedText.TextSecondary>
        </Box>
        <Box p="0 12px">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{MarketSortMethod.Liquidity}</Trans>
          </ThemedText.TextSecondary>
        </Box>
        <Box p="0 12px">
          <HeaderCell category={MarketSortMethod.Marketcap} justify="flex-start" />
        </Box>
        <Box p="0 12px">
          <HeaderCell category={MarketSortMethod.Participants} justify="flex-start" />
        </Box>
        <Box display="flex" justifyContent="flex-end" p="0 12px">
          <HeaderCell category={MarketSortMethod.CreatedIn} justify="flex-end" />
        </Box>
        <Box p="0 12px">
          <ThemedText.TextSecondary fontSize={12} fontWeight={700}>
            <Trans>{MarketSortMethod.Description}</Trans>
          </ThemedText.TextSecondary>
        </Box>
      </StyledTrRow>
      <Box mt="16px" width="100%">
        {!data ? (
          <Column gap="16px" width="100%">
            <LoadingRows />
          </Column>
        ) : (
          <Column gap="16px">
            {data?.map((item: any, i: number) => (
              <InfoItem item={item} key={i} />
            ))}
          </Column>
        )}
      </Box>
      <StyledPagination style={{ marginTop: '8px' }} count={count} onChange={handleNextPage} />
    </StyledInfoList>
  )
}

function InfoItem({ item }: any) {
  const { wethBalance, tokenBalance } = usePoolLiquidity(item.launchPoolInfo.pool.id, item.address)

  return (
    <NavLink to={`/infoDetail?token=${item.address}`} style={{ textDecoration: 'none', width: '100%' }}>
      <StyledThRow>
        <Box display="flex" gap="16px" p="0 12px">
          <img src={item?.metadata?.image_url} width="64px" height="64px" />
          <Column align="flex-start">
            <ThemedText.TextPrimary fontSize={16} fontWeight={700}>
              <Trans>{item.symbol}</Trans>
            </ThemedText.TextPrimary>
            <ThemedText.TextPrimary fontSize={12} fontWeight={400} mt="8px">
              <Trans>{shortenAddress(item.address)}</Trans>
            </ThemedText.TextPrimary>
            <Box mt="12px">
              <LinkBox metadata={item?.metadata} />
            </Box>
          </Column>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center" gap="8px" p="0 12px">
          <ThemedText.TextPrimary fontSize={12} fontWeight={700}>
            {computeNumUnit(wethBalance?.toExact())} ETH
          </ThemedText.TextPrimary>
          <ThemedText.TextPrimary fontSize={12} fontWeight={700}>
            {computeNumUnit(tokenBalance?.toExact())} {item.symbol}
          </ThemedText.TextPrimary>
        </Box>
        <Box display="flex" alignItems="center" p="0 12px">
          <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
            ${computeNumUnit(item.marketCap)}
          </ThemedText.TextPrimary>
        </Box>
        <Box display="flex" alignItems="center" gap="8px" p="0 12px">
          <StyledParticipantsIcon />
          <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
            {formatAmount(item.userCount, 0, true)}
          </ThemedText.TextPrimary>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-end" justifyContent="center" gap="4px" p="0 12px">
          <ThemedText.TextPrimary fontSize={14} fontWeight={700}>
            {handleCreatedTime(item.createdAtTimestamp)}
          </ThemedText.TextPrimary>
        </Box>
        <Box p="0 12px">
          <ThemedText.TextSecondary fontSize={12} fontWeight={400} className="desc">
            <Trans>{item?.metadata?.description}</Trans>
          </ThemedText.TextSecondary>
        </Box>
      </StyledThRow>
    </NavLink>
  )
}
