import type { Signer } from '@ethersproject/abstract-signer'
import { AddressZero } from '@ethersproject/constants'
import { Contract } from '@ethersproject/contracts'
import type { Provider } from '@ethersproject/providers'

import { isAddress } from './addresses'
export function getContract(
  address: string,
  ABI: any,
  // provider: providers.JsonRpcProvider | providers.FallbackProvider
  signer?: Signer | Provider
): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, signer)
}

export function getContract2(
  address: string,
  ABI: any,
  // provider: providers.JsonRpcProvider | providers.FallbackProvider
  signer?: Signer | Provider
): Contract | undefined {
  if (!address || !isAddress(address) || address === AddressZero) return

  return new Contract(address, ABI, signer)
}
