import BigNumber from 'bignumber.js'
import { ethers } from 'ethers/lib'

export const BN = (val: number | string): BigNumber => new BigNumber(val)
/**
 *
 * @param n  ethers.BigNumber
 * @param decimals   The default decimals bit to be converted is 18
 * @default decimals 18
 * @returns
 */
export const toFromBN = (n?: ethers.BigNumber, decimals = 18): BigNumber => {
  return BN(ethers.utils.formatUnits(n || 0, decimals))
}

export const fromWei = (n: string | number, decimals = 18): BigNumber => {
  return BN(n).div(BN(10).pow(decimals))
}
export const toWei = (n: string | number, decimals = 18): BigNumber => {
  return BN(n).times(BN(10).pow(decimals))
}

export const fromSqrt96Wei = (n?: string | number, token0Decimals?: number, token1Decimals?: number): BigNumber => {
  const tempValue = BN(n || 0)
    .times(n || 0)
    .div(BN(2).pow(192))
  if (!token0Decimals || !token1Decimals) return tempValue
  if (BN(token0Decimals).eq(token1Decimals)) {
    return tempValue
  } else if (BN(token0Decimals).gt(token1Decimals)) {
    return tempValue.times(BN(10).pow(BN(token0Decimals).minus(token1Decimals)))
  } else {
    return tempValue.dividedBy(BN(10).pow(BN(token1Decimals).minus(token0Decimals)))
  }
}

export const toSqrt96 = (n: string | number): BigNumber => {
  return BN(
    BN(n || 0)
      .sqrt()
      .toFixed()
  ).times(BN(2).pow(96))
}

export const formFeeWei = (n: ethers.BigNumber, decimals = 6): BigNumber => {
  return BN(ethers.utils.formatUnits(n, decimals))
}
const toStringFromBN = function (bn: number | string, decimals = 18): string {
  const res = BN(bn).toFixed(decimals)

  return res === 'NaN' ? '0' : res
}

// eslint-disable-next-line import/no-unused-modules
export const fromBN = (bn: number | string, decimals = 18): ethers.BigNumber => {
  return ethers.utils.parseUnits(toStringFromBN(bn, decimals), decimals)
}
