import '@rainbow-me/rainbowkit/styles.css'

import '@rainbow-me/rainbowkit/styles.css'

import { connectorsForWallets, getDefaultWallets } from '@rainbow-me/rainbowkit'
import { Chain, configureChains, createConfig } from 'wagmi'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import {
  braveWallet,
  coinbaseWallet,
  injectedWallet,
  metaMaskWallet,
  okxWallet,
  rabbyWallet,
  rainbowWallet,
  walletConnectWallet,
} from "@rainbow-me/rainbowkit/wallets";
const SepoliaTest: Chain = {
  id: 421614,
  network: 'ARBITRUM_SEPOLIA Testnet',
  name: 'ARBITRUM_SEPOLIA Testnet',
  nativeCurrency: {
    name: 'ARBITRUM_SEPOLIA Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    infura: {
      http: ['https://arbitrum-sepolia.infura.io/v3'],
    },
    default: {
      http: ['https://sepolia-rollup.arbitrum.io/rpc'],
    },
    public: {
      http: ['https://sepolia-rollup.arbitrum.io/rpc'],
    },
  },
  testnet: true,
}

const BLAST_MAIN: Chain = {
  id: 81457,
  network: 'BLAST ',
  name: 'BLAST ',
  nativeCurrency: {
    name: 'BLAST Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    infura: {
      http: ['https://blast.infura.io/v3'],
    },
    default: {
      http: ['https://rpc.blast.io'],
    },
    public: {
      http: ['https://rpc.blast.io'],
    },
  },
  testnet: true,
  // hasIcon: true,
  // iconUrl: 'https://raw.githubusercontent.com/metaverseblock/token-icon/main/168587773/BLAST.png',
}

const BLAST_TEST: Chain = {
  id: 168587773,
  network: 'BLAST Testnet',
  name: 'BLAST Testnet',
  nativeCurrency: {
    name: 'BLAST Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    infura: {
      http: ['https://blast-sepolia.infura.io/v3'],
    },
    default: {
      http: ['https://sepolia.blast.io'],
    },
    public: {
      http: ['https://sepolia.blast.io'],
    },
  },
  testnet: true,
  // hasIcon: true,
  // iconUrl: 'https://raw.githubusercontent.com/metaverseblock/token-icon/main/168587773/BLAST.png',
}

const ZIRCUIT_TEST: Chain = {
  id: 48899,
  network: 'ZIRCUIT Testnet',
  name: 'ZIRCUIT Testnet',
  nativeCurrency: {
    name: 'ZIRCUIT Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://zircuit1.p2pify.com/'],
    },
    public: {
      http: ['https://zircuit1.p2pify.com/'],
    },
  },
  testnet: true,
  // hasIcon: true,
  // iconUrl: 'https://raw.githubusercontent.com/metaverseblock/token-icon/main/48899/ZIRCUIT.png',
}
const ZIRCUIT_MAIN: Chain = {
  id: 48900,
  network: 'ZIRCUIT',
  name: 'ZIRCUIT',
  nativeCurrency: {
    name: 'ZIRCUIT Ether',
    symbol: 'ETH',
    decimals: 18,
  },
  rpcUrls: {
    default: {
      http: ['https://zircuit1-mainnet.p2pify.com/'],
    },
    public: {
      http: ['https://zircuit1-mainnet.p2pify.com/'],
    },
  },
  testnet: true,
  // hasIcon: true,
  // iconUrl: 'https://raw.githubusercontent.com/metaverseblock/token-icon/main/48899/ZIRCUIT.png',
}

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [ZIRCUIT_MAIN],
  [alchemyProvider({ apiKey: 'bBUosx2q8Pm3dJKBkoxKxPoW5JwPNFDy' }), publicProvider()]
  // [infuraProvider({ apiKey: 'f7d803169aa248f28a510d0d1889951c' })]
)
const walletConnectProjectId = 'd9e02d8e4a4bb8fe574b63430fa1cf34'
const needsInjectedWalletFallback =
  typeof window !== "undefined" &&
  window.ethereum &&
  !window.ethereum.isMetaMask &&
  !window.ethereum.isCoinbaseWallet;


  const connectors = connectorsForWallets([{
    groupName: "ROGUEX",
    wallets: [
      metaMaskWallet({
        projectId: walletConnectProjectId,
        chains,
        shimDisconnect: true,
      }),
      braveWallet({ chains, shimDisconnect: true }),
      rainbowWallet({ projectId: walletConnectProjectId, chains }),
      walletConnectWallet({
        projectId: walletConnectProjectId,
        chains,
        options: {
          projectId: walletConnectProjectId,
          showQrModal: false,
          qrModalOptions: {
            explorerRecommendedWalletIds: [
              "0b415a746fb9ee99cce155c2ceca0c6f6061b1dbca2d722b3ba16381d0562150",
              "1aedbcfc1f31aade56ca34c38b0a1607b41cccfa3de93c946ef3b4ba2dfab11c",
            ],
          },
        },
      }),
      coinbaseWallet({ appName: "DYNA", chains }),
      rabbyWallet({ chains, shimDisconnect: true }),
      okxWallet({ projectId: walletConnectProjectId, chains }),
      ...(needsInjectedWalletFallback
        ? [injectedWallet({ chains, shimDisconnect: true })]
        : []),
    ],
  }])
  

export const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
  webSocketPublicClient,
})

export const chain = chains
